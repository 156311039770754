import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes, routes } from '@/config/routes'
import { IpRegex, NaturalNumberRegex } from '@/config/config'
import { useModal } from '@/context/ModalContext'
import { incidentDetailModal } from '@/components/modals/incidents/modals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { ICctv, TableSampler } from '@/context/types'
import { AxiosRequestConfig } from 'axios'
import searchToParams from '@/utils/paramConverters/searchToParams'
import { ProjectsCctvTableColumns } from '@/config/tables/lists/projects/projectsCctv'
import { projectSearchInfoModal } from '@/components/modals/common/info'
import { useCctvListContext } from '@/context/tables/CctvListContext'
import { getCctvsListFilter } from '@/config/listFilters'
import paramsToSampler from '@/utils/paramConverters/paramsToSampler'
import { fetchProjectFields } from '@/api/queries/projects'

/** Группа поиска для панели управления проектом */
export default function ToolbarSearchGroup() {
  const { projectId } = useParams()
	const { showModalDialog } = useModal()
  const { setRecordListSampler: setCctvListSampler } = useCctvListContext()
	const [searchString, setSearchString] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const navigate = useNavigate()

	const handleSearchStringChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setSearchString(event.target.value)
	}, [])

	const getCctvIds = useCallback(async (filter: AxiosRequestConfig<any>['params'], notFoundStr: string) => {
		const res = await axios.get<ICctv[]>(
			apiRoutes.cctvs, {
				params: {
					...filter,
					...getCctvsListFilter(projectId),
					'groups[]': 'id:read',
				},
			})
		if (!res.data.length)
			throw new Error(notFoundStr)
		
		return res.data.map(c => c.id)
	}, [projectId])

	const handleSearchCctvByNumber = useCallback(async () => {
		const clearSearchString = searchString.trim()
		try {
			setIsLoading(true)
			
			const cctvIds = await getCctvIds(
				{ number: clearSearchString },
				`ТВН ${clearSearchString} не найден`
			)

			if (cctvIds.length === 1)
				navigate(projectId ? routes.projectCctv(projectId, cctvIds[0]) : routes.exploitationCctv(cctvIds[0]))
			else {
				navigate(projectId ? routes.projectCctvs(projectId) : routes.exploitationCctvs)
				setCctvListSampler(
					paramsToSampler({ number: clearSearchString }),
					`Номер: ${clearSearchString}`,
				)
			}
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: 'Поиск ТВН: ошибка',
				content: await getErrorMessage(e, `Ошибка при поиске ТВН по номеру ${clearSearchString}`),
			})
		} finally {
			setIsLoading(false)
		}
	}, [searchString, getCctvIds, projectId, navigate, showModalDialog])

	/** Поиск ТВН по IP оборудования */
	const handleSearchCctvByHardwareIp = useCallback(async () => {
		const clearSearchString = searchString.trim()
		if (!clearSearchString)
			return
		if (!IpRegex.test(clearSearchString))
			return showModalDialog({
				type: 'error',
				header: 'Поиск',
				content: 'Введите корректный IP-адрес'
			})
		try {
			setIsLoading(true)
			
			const cctvIds = await getCctvIds(
				{ 'hardware.networkHost.ipAddress': clearSearchString },
				`ТВН с оборудованием ${clearSearchString} не найден`
			)

			if (cctvIds.length === 1)
				navigate(projectId ? routes.projectCctv(projectId, cctvIds[0]) : routes.exploitationCctv(cctvIds[0]))
			else {
				navigate(projectId ? routes.projectCctvs(projectId) : routes.exploitationCctvs)
				setCctvListSampler(
					paramsToSampler({ 'hardware.networkHost.ipAddress': clearSearchString }),
					`IP адрес: ${clearSearchString}`
				)
			}
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: 'Поиск ТВН: ошибка',
				content: await getErrorMessage(e, `Ошибка при поиске ТВН с оборудованием ${clearSearchString}`),
			})
		} finally {
			setIsLoading(false)
		}
	}, [searchString, getCctvIds, projectId, navigate, setCctvListSampler, showModalDialog])

	/** Поиск ТВН по адресу */
	const handleSearchCctvByAddress = useCallback(async () => {
		const clearSearchString = searchString.trim()
		if (!clearSearchString) return
		try {
			setIsLoading(true)

			const params = searchToParams(ProjectsCctvTableColumns, 'address', clearSearchString )

			const cctvIds = await getCctvIds(
				params,
				`ТВН по адресу «${clearSearchString}» не найдено. Поиск осуществляется по столбцу Адрес главной таблицы проекта.
				 Вводите точный фрагмент адреса ТВН.`
			)

			if (cctvIds.length === 1)
				navigate(projectId ? routes.projectCctv(projectId, cctvIds[0]) : routes.exploitationCctv(cctvIds[0]))
			else {
				navigate(projectId ? routes.projectCctvs(projectId) : routes.exploitationCctvs)

				setCctvListSampler(paramsToSampler(params), `Адрес: ${clearSearchString}`,
				)
			}
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: 'Поиск ТВН: ошибка',
				content: await getErrorMessage(e, `Ошибка при поиске ТВН по адресу «${clearSearchString}»`),
			})
		} finally {
			setIsLoading(false)
		}
	}, [searchString, getCctvIds, projectId, navigate, setCctvListSampler, showModalDialog])

	const handleSearchService = useCallback(async () => {
		const clearSearchString = searchString.trim();
		try {
			setIsLoading(true);
	
			const projectFields = await fetchProjectFields();
			const serviceField = projectFields.find(field => field.name === 'УСЛУГА');
	
			if (!serviceField) {
				throw new Error('Поле "УСЛУГА" не найдено');
			}
	
			const fieldId = serviceField.id;
			const queryKey = `orProjectFields[${fieldId}]`;
	
			const cctvIds = await getCctvIds(
				{ [queryKey]: clearSearchString },
				`ТВН ${clearSearchString} не найден`
			);
	
			if (cctvIds.length === 1) {
				navigate(projectId ? routes.projectCctv(projectId, cctvIds[0]) : routes.exploitationCctv(cctvIds[0]));
			} else {
				navigate(projectId ? routes.projectCctvs(projectId) : routes.exploitationCctvs);
				setCctvListSampler(
					paramsToSampler({ [queryKey]: clearSearchString }),
					`Номер: ${clearSearchString}`
				);
			}
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: 'Поиск ТВН: ошибка',
				content: await getErrorMessage(e, `Ошибка при поиске ТВН по номеру ${clearSearchString}`),
			});
		} finally {
			setIsLoading(false);
		}
	}, [searchString, getCctvIds, projectId, navigate, setCctvListSampler, showModalDialog]);

	/** Автоматический поиск */
	const handleSearch = useCallback((e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		
		const clearSearchString = searchString.trim()
		
		if (NaturalNumberRegex.test(clearSearchString))
			return handleSearchCctvByNumber()
		
		if (IpRegex.test(clearSearchString))
			return handleSearchCctvByHardwareIp()
		
		handleSearchCctvByNumber()
	}, [searchString, handleSearchCctvByNumber, handleSearchCctvByHardwareIp])

	/** Поиск инцидента */
	const handleSearchIncidentById = useCallback(() => {
		const clearSearchString = searchString.trim()
		if (!clearSearchString) return
		if (!NaturalNumberRegex.test(clearSearchString))
			return showModalDialog({
				type: 'error',
				header: 'Поиск',
				content: 'Номер инцидента должен быть числом'
			})
		showModalDialog(incidentDetailModal(projectId, +clearSearchString, () => { }))
	}, [searchString, showModalDialog, projectId])

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(projectSearchInfoModal())
  }, [showModalDialog])

	return (
		<form onSubmit={handleSearch}>
			<div className='input-group input-group-sm flex-nowrap'>
        <button
          className='btn btn-link p-0 ms-auto'
          onClick={handleShowFilterInfo}
          type='button'
        >
          <FontAwesomeIcon icon={faInfoCircle} size='lg' className='mx-3' />
        </button>
				<span className='input-group-text'>
					<FontAwesomeIcon icon={faMagnifyingGlass} />
				</span>
				<input
					className='form-control'
					type='text'
					value={searchString}
					placeholder='Поиск...'
					enterKeyHint='search'
					disabled={isLoading}
					onChange={handleSearchStringChanged} />
				<button
					className='btn btn-outline-primary text-nowrap'
					type='button'
					disabled={isLoading}
					onClick={handleSearchCctvByNumber}>
					ТВН №
				</button>
				<button
					className='btn btn-outline-primary'
					type='button'
					disabled={isLoading}
					onClick={handleSearchCctvByHardwareIp}>
					IP
				</button>
				<button
					className='btn btn-outline-primary'
					type='button'
					disabled={isLoading}
					onClick={handleSearchCctvByAddress}>
					Адрес
				</button>
				<button
					className='btn btn-outline-primary'
					type='button'
					disabled={isLoading}
					onClick={handleSearchIncidentById}>
					ТТ
				</button>
				<button 
				    className='btn btn-outline-primary'
					type='button'
					disabled={isLoading} 
					onClick={handleSearchService}>
                    Услуга
				</button>
			</div >
		</form >
	)
}
