import { useCallback, useEffect, useMemo, useState } from 'react'
import AuditProjectHeader from '@/components/audit/AuditProjectHeader'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import useTableRecordDelete from '@/hooks/useTableRecordDelete'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import { usePecListContext } from '@/context/tables/PecListContext'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import TableRow from '@/components/common/table/TableRow'
import TableHead from '@/components/common/table/TableHead'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import Loader from '@/components/common/Loader'
import PaginationWSP from '@/components/common/PaginationWSP'
import { apiRoutes, routes } from '@/config/routes'
import { ApiResource, IPec, IPecStatusField } from '@/context/types'
import { isObjectEmpty } from '@/utils/tools'
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'
import {
  PecTableColumnName, PecTableColumnNameList, PecTableColumns, preparePecAdditionalCols,
} from '@/config/tables/lists/audit/pec'
import TableToolbar from '@/components/common/table/TableToolbar'
import { createEditPecInfoModal } from '@/components/modals/pec/modals'
import UploadBtn from '@/components/common/btns/UploadBtn'
import { usePecStatusFieldListContext } from '@/context/entityFields/PecStatusFieldListContext'
import PermissionRequired from '@/components/auth/PermissionRequired'
import { useQueryClient } from '@tanstack/react-query'
import { typedObjectEntries } from '@/utils/object'
import PecListBtnsRow from '@/components/audit/stats/PecsStatBtnsRow'
import { UserRole } from '@/config/roles'
import PecsStat, { StatType } from '@/components/audit/stats/PecsStatWrapper'
import filterToParams from '@/utils/paramConverters/filterToParams'
import PecsTableUserMenu from '@/components/common/table/PecsTableUserMenu'
import { patchPecBrigadier, patchPecExecutor, patchPecResponsibleFromCustomer, patchPecContractor, patchPecWorkBeginDate } from '@/api/queries/audit'
import PecsTableOrganizationMenu from '../common/table/PecsTableOrganization'
import PecsTableWorkBeginDateMenu from '../common/table/PecsTableWorkBeginDate'


export const PecListPageContent = () => {
  useDocumentTitle('УИКи')
  const { projectId } = useParams()
  const { showModalDialog } = useModal()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const {
    recordList: pecStatusFields,
    isLoading: isStatusFieldsLoading,
    loadRecordList: loadPecStatusFields,
  } = usePecStatusFieldListContext()
  const searchParamsStatusFieldId = searchParams.get('statusField') as IPecStatusField['id'] | undefined
  const [activeStatusFieldId, setActiveStatusFieldId] = useState(searchParamsStatusFieldId)
  const [activeStatType, setActiveStatType] = useState(StatType.common)
  const showDeleteModalDialog = useTableRecordDelete<IPec>(apiRoutes.pec, 'УИК', routes.auditProjectPec)
  const { hasPermission, hasRole, user } = useAuthContext()
  const availableCols = useTableAvailableCols(PecTableColumnNameList, PecTableColumns)
  const {
    recordList,
    count,
    page,
    pageCount,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    sortFieldName,
    sortDirection,
    isAllRecordsSelected,
    selectedRecordIdSet,
    fullRecordIdSet,
    isRecordListLoading,
    isFullIdRecordListLoading,
    loadRecordList,
    samplerLabel,
    sampler,
    setRecordListPage,
    setRecordListFilter,
    setRecordListSort,
    setRecordListSampler,
    selectRecord,
    deselectRecord,
    selectAllRecords,
    deselectAllRecords,
  } = usePecListContext()
  const navigate = useNavigate()

  if (pecStatusFields.length && !activeStatusFieldId)
    setActiveStatusFieldId(pecStatusFields[0].id)

  const loadFilteredRecordList = useCallback(async () => {
    if (!activeStatusFieldId)
      return

    let filterKey
    if (hasRole(UserRole.responsible_from_customer)) // implying that user can have only one of this roles
    
    if (hasRole(UserRole.kns))
    
    if (hasRole(UserRole.brigadier))
      filterKey = 'brigadiers'
    if (hasRole(UserRole.executor))
      filterKey = 'executor'

    await loadRecordList(
      filterKey
        ? filterToParams({ [`${filterKey}/${activeStatusFieldId}`]: `${user!.name}!` })
        : undefined,
    )
  }, [loadRecordList, activeStatusFieldId])

  useEffect(() => {
    loadFilteredRecordList()
  }, [loadFilteredRecordList])

  useEffect(() => {
    setSearchParams(activeStatusFieldId ? { statusField: activeStatusFieldId } : {})
  }, [activeStatusFieldId])

  const reload = useCallback(async () => {
    await loadPecStatusFields()
    await loadFilteredRecordList()
    await queryClient.resetQueries({ queryKey: ['pec_common_statistic'] })
    await queryClient.resetQueries({ queryKey: ['pec_status_field_statistic'] })
    await queryClient.resetQueries({ queryKey: ['pec_slice_finished_statistic'] })
    await queryClient.resetQueries({ queryKey: ['pec_slice_status_statistic'] })
  }, [loadPecStatusFields, loadFilteredRecordList])

  useEffect(() => {
    return () => {
      clearFilter()
    }
  }, [])

  const {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter,
  } = useTableFilterForm(filter, setRecordListFilter)

  const createRecord = useCallback(() => {
    showModalDialog(createEditPecInfoModal(
      projectId!,
      pecId => navigate(routes.auditProjectPec(projectId!, pecId)),
    ))
  }, [projectId, showModalDialog, navigate])

  const deleteRecords = useCallback(() => {
    showDeleteModalDialog(selectedRecordIdSet, deselectAllRecords, reload)
  }, [selectedRecordIdSet, deselectAllRecords, reload])

  /** Сбросить выборку */
  const handleClearSampler = useCallback(() => {
    setRecordListSampler({}, '')
  }, [setRecordListSampler])

  const handleClickStatType = (type: StatType) => {
    setActiveStatType(type)

    if (Object.keys(sampler).length || samplerLabel)
      handleClearSampler()
  }

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('УИК', {
      importApiResource: hasPermission('importPecs') ? ApiResource.Pec : undefined,
      templateLinkParams: { project_id: projectId! },
    }))
  }, [showModalDialog, projectId, hasPermission])

  const additionalColumns = useMemo(() =>
      preparePecAdditionalCols({
        contractors: [],
   //     responsibleFromCustomers: [],
        brigadiers: [],
        executors: [],
        statusFields: [],
        workBeginDates: [],
      } as unknown as IPec /* //todo-sem костыль чтобы в шапке таблицы не пропадали доп столбцы */, activeStatusFieldId!)
    , [activeStatusFieldId])

  const customColumnLabels = useMemo(() => ({
   //  responsibleFromCustomer: <PecsTableUserMenu
   //   label={additionalColumns.find(col => col.key === 'responsibleFromCustomer')?.label ?? ''}
   //    roles={[UserRole.responsible_from_customer]}
   //   deselectAllRecords={deselectAllRecords}
   //   hasEditPermission={hasPermission('pecEdit')}
   //    loadRecordList={loadFilteredRecordList}
   //   saveFunction={async (pecId, userId) => {
   //     await patchPecResponsibleFromCustomer(pecId, activeStatusFieldId!, userId)
   // }}
   // selectedRecordIdSet={selectedRecordIdSet}
   // />,
    contractor: <PecsTableOrganizationMenu
      label={additionalColumns.find(col => col.key === 'contractor')?.label ?? ''}
      deselectAllRecords={deselectAllRecords}
      hasEditPermission={hasPermission('pecEdit')}
      loadRecordList={loadFilteredRecordList}
      saveFunction={async (pecId, organizationId) => {
       await patchPecContractor(pecId, activeStatusFieldId!, organizationId)
      }}
      selectedRecordIdSet={selectedRecordIdSet}
    />,
    brigadier: <PecsTableUserMenu
      label={additionalColumns.find(col => col.key === 'brigadier')?.label ?? ''}
      roles={[UserRole.brigadier]}
      deselectAllRecords={deselectAllRecords}
      hasEditPermission={hasPermission('pecBrigadierEdit')}
      loadRecordList={loadFilteredRecordList}
      saveFunction={async (pecId, userId) => {
        await patchPecBrigadier(pecId, activeStatusFieldId!, userId)
      }}
      selectedRecordIdSet={selectedRecordIdSet}
    />,
    executor: <PecsTableUserMenu
      label={additionalColumns.find(col => col.key === 'executor')?.label ?? ''}
      roles={[UserRole.executor]}
      deselectAllRecords={deselectAllRecords}
      hasEditPermission={hasPermission('pecExecutorEdit')}
      loadRecordList={loadFilteredRecordList}
      saveFunction={async (pecId, userId) => {
        await patchPecExecutor(pecId, activeStatusFieldId!, userId)
      }}
      selectedRecordIdSet={selectedRecordIdSet}
    />,
    workBeginDates: <PecsTableWorkBeginDateMenu
      label={additionalColumns.find(col => col.key === 'workBeginDates')?.label ?? ''}
      deselectAllRecords={deselectAllRecords}
      hasEditPermission={hasPermission('pecWorkBeginDateEdit')}
      loadRecordList={loadFilteredRecordList}
      saveFunction={async (pecId, date) => {
        await patchPecWorkBeginDate(pecId, activeStatusFieldId!, date)
      }}
      selectedRecordIdSet={selectedRecordIdSet}
    />,
  }), [additionalColumns, deselectAllRecords, loadFilteredRecordList, selectedRecordIdSet, activeStatusFieldId])

  //todo-sem костыльное переписывание value чтобы добавить в url activeStatusFieldId
  const columns: typeof PecTableColumns = {
    ...PecTableColumns,
    number: {
      ...PecTableColumns['number'],
      value: pec =>
        <NavLink to={`${routes.auditProjectPec(pec.project.id, pec.id)}?statusField=${activeStatusFieldId}`}>
          {pec.number}
        </NavLink>,
    },
  }

  const isLoading = isRecordListLoading || isFullIdRecordListLoading || isStatusFieldsLoading

  const hasSelectColumn = hasPermission('pecDelete')

  const activeStatusFieldName = pecStatusFields.find(sf => sf.id === activeStatusFieldId)?.name

  const statTypes = {
    [StatType.common]: 'Общие данные по проекту',
    [StatType.status]: `Статистика по виду работ "${activeStatusFieldName}"`,
    [StatType.slice]: `Срез по виду работ "${activeStatusFieldName}"`,
  } satisfies Record<StatType, string>

  if (!activeStatusFieldId)
    return (
      <>
        <AuditProjectHeader />
        <div className='container-fluid mt-3'>
          Видов работ не найдено
        </div>
      </>
    )

  return (
    <>
      <AuditProjectHeader />
      <div className='container-fluid mt-3'>
        <PecListBtnsRow
          label='Вид работ'
          btns={pecStatusFields.map(({ id, name }) => ({
            id,
            label: name,
            selected: activeStatusFieldId === id,
          }))}
          onClick={setActiveStatusFieldId}
        />
        {hasPermission('pecStatRead') && activeStatusFieldName &&
          <>
            <PecListBtnsRow
              label='Статистика'
              btns={typedObjectEntries(statTypes).map(([type, label]) => ({
                id: type,
                label,
                selected: activeStatType === type,
              }))}
              onClick={handleClickStatType}
            />
            <div className='mt-3'>
              <PecsStat type={activeStatType} pecStatusFieldId={activeStatusFieldId} />
            </div>
          </>
        }
      </div>
      <div className='d-flex flex-column'>
        <TableToolbar
          hasSampler={hasPermission('pecStatRead')}
          samplerLabel={samplerLabel}
          recordListLength={recordList.length}
          recordListCount={count}
          recordsPerPage={recordsPerPage}
          setRecordsPerPage={setRecordsPerPage}
          selectedRecordIdSetSize={selectedRecordIdSet.size}
          fullRecordIdSet={fullRecordIdSet}
          clearFilter={clearFilter}
          onClearSampler={handleClearSampler}
          onReload={reload}
          exportBtnLabel='Массовый экспорт УИК'
          exportApiRoute={hasPermission('exportPecs') ? apiRoutes.exportProjectPecs(projectId!) : ''}
          importBtnLabel='Массовый импорт УИК'
          importApiRoute={hasPermission('importPecs') ? apiRoutes.importPecs(projectId!) : ''}
          handleShowFilterInfo={handleShowFilterInfo}
          onClickCreate={createRecord}
          canCreate={hasPermission('pecCreate')}
          onClickDelete={deleteRecords}
          canDelete={hasPermission('pecDelete')}
          additionalBtns={
            <>
              <PermissionRequired permission='importPecs'>
                <UploadBtn
                  label='Массовое обновление УИК'
                  accept='.csv'
                  apiRoute={apiRoutes.updatePecs(projectId!)}
                />
              </PermissionRequired>
              <PermissionRequired permission='importPecUsers'>
                <UploadBtn
                  label='Импорт пользователей'
                  accept='.csv'
                  apiRoute={apiRoutes.importAuditUsers()}
                />
              </PermissionRequired>
            </>
          }
          isAudit
        />
        <div className='container-fluid' style={{ overflowX: 'auto' }}>
          <table className='table table-sm table-bordered table-hover' style={{ height: 1 }}>
            {!isLoading && activeStatusFieldId &&
              <>
                <TableHead
                  columnNameList={availableCols}
                  columns={columns}
                  additionalColumns={additionalColumns}
                  customColumnLabels={customColumnLabels}
                  isAllRecordsSelected={isAllRecordsSelected}
                  selectAllRecords={hasSelectColumn ? selectAllRecords : undefined}
                  deselectAllRecords={hasSelectColumn ? deselectAllRecords : undefined}
                  recordListSortFieldName={sortFieldName}
                  recordListSortDirection={sortDirection}
                  setRecordListSort={setRecordListSort}
                  filterForm={filterForm}
                  updateFilterForm={updateFilterForm}
                  applyFilter={applyFilter}
                  top={0}
                />
                <tbody>
                  {!recordList.length
                    ? <EmptyTableRow columnCount={availableCols.length + 1}>
                      {pecStatusFields.length
                        ? (isObjectEmpty(filter) ? 'УИК нет' : 'УИК не найдено')
                        : 'Виды работ не найдены'
                      }
                    </EmptyTableRow>
                    : recordList.map(record =>
                      <TableRow<IPec, PecTableColumnName>
                        key={record.id}
                        columnNameList={availableCols}
                        columns={columns}
                        additionalColumns={preparePecAdditionalCols(record, activeStatusFieldId)}
                        record={record}
                        selectRecord={hasSelectColumn ? selectRecord : undefined}
                        selectedRecordIdSet={hasSelectColumn ? selectedRecordIdSet : undefined}
                        deselectRecord={hasSelectColumn ? deselectRecord : undefined}
                        expandable
                      />,
                    )
                  }
                </tbody>
              </>
            }
          </table>
          {isLoading && <Loader />}
          <div style={{ position: 'sticky', bottom: 0, marginLeft: 12 }}>
            <PaginationWSP
              currentPage={page}
              setCurrentPage={setRecordListPage}
              lastPage={pageCount}
            />
          </div>
        </div>
      </div>
    </>
  )
}
