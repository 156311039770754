import { Navigate, Route, Routes } from 'react-router'
import { routes } from '@/config/routes'
import { CctvAcceptanceStatusListContextProvider } from '@/context/statuses/CctvAcceptanceStatusListContext'
import { IdAcceptanceStatusListContextProvider } from '@/context/statuses/IdAcceptanceStatusListContext'
import { CctvChannelStatusListContextProvider } from '@/context/statuses/CctvChannelStatusListContext'
import { CctvInstallationStatusListContextProvider } from '@/context/statuses/CctvInstallationStatusListContext'
import { ChannelListContextProvider } from '@/context/tables/ChannelListContext'
import { ProjectContextProvider } from '@/context/project/ProjectContext'
import { ProjectListContextProvider } from '@/context/tables/ProjectListContext'
import { ServiceListContextProvider } from '@/context/tables/ServiceListContext'
import { AddressListContextProvider } from '@/context/tables/AddressListContext'
import { TabsContextProvider } from '@/context/TabsContext'
import CatalogListPageContent from '@/components/catalogs/CatalogListPageContent'
import CatalogPageContainer from '@/components/catalogs/CatalogPageContainer'
import ChannelTablePageContent from '@/components/channels/ChannelTablePageContent'
import { CommunicationPageContent } from '@/components/communication/CommunicationPageContent'
import CreateProjectPageContent from '@/components/ProjectsContent/CreateProjectPageContent'
import CreateRecordPageContent from '@/components/catalogs/CreateRecordPageContent'
import EditProjectPageContent from '@/components/ProjectsContent/EditProjectPageContent'
import EditRecordPageContent from '@/components/catalogs/EditRecordPageContent'
import Error404PageContent from '@/components/common/Error404PageContent'
import ProjectListPageContent from '@/components/ProjectsContent/ProjectListPageContent'
import RecordListPageContent from '@/components/catalogs/RecordListPageContent'
import RecordPageContent from '@/components/catalogs/RecordPageContent'
import ServicePageContent from '@/components/communication/ServicePageContent'
import ServiceTablePageContent from '@/components/communication/ServiceTablePageContent'
import AddressTablePageContent from '@/components/channels/AddressTablePageContent'
import UserPageContainer from '@/components/common/UserPageContainer'
import BillingCommonTablePageContent from '@/components/communication/BillingCommonTablePageContent'
import BillingRevenueTablePageContent from '@/components/communication/BillingRevenueTablePageContent'
import BillingExpenseTablePageContent from '@/components/communication/BillingExpenseTablePageContent'
import { BillingCommonListContextProvider } from '@/context/tables/BillingCommonListContext'
import { BillingRevenueListContextProvider } from '@/context/tables/BillingRevenueListContext'
import { BillingExpenseListContextProvider } from '@/context/tables/BillingExpenseListContext'
import ActFormationTableContent from '@/components/communication/ActFormationTableContent'
import ChannelPageContent from '@/components/channels/ChannelPageContent'
import ConstructionObjectTablePageContent from '@/components/communication/ConstructionObjectTablePageContent'
import { ConstructionObjectListContextProvider } from '@/context/tables/ConstructionObjectListContext'
import { ServiceTypeKindNames } from '@/context/types'
import { useAuthContext } from '@/context/auth/AuthContext'
import CCTVListPageContent from '@/components/CctvListPageContent/CctvListPageContent'
import { Cctv } from '@/components/CctvListPageContent/Cctv/Cctv'
import { ProjectFieldListContextProviderWrapper } from '@/context/entityFields/ProjectFieldListContext'
import { DashBoard } from '@/components/ProjectsContent/DashBoard/DashBoard'
import { exploitationReportApiCodes, projectReportApiCodes } from '@/config/reports'
import Report from '@/components/reports/Report'
import MonitoringCctvTable from '@/components/ProjectsContent/monitoring/table/MonitoringCctvTable'
import MonitoringCctvMap from '@/components/ProjectsContent/monitoring/map/MonitoringCctvMap'
import { ChannelStatusListContextProvider } from '@/context/statuses/ChannelStatusListContext'
import { ChannelFieldListContextProvider } from '@/context/entityFields/ChannelFieldListContext'
import AddressPageContent from '@/components/channels/AddressPageContent'
import ChannelPaymentsTablePageContent from '@/components/channels/ChannelPaymentsTablePageContent'
import { ChannelPaymentsListContextProvider } from '@/context/tables/ChannelPaymentsListContext'
import { MonitoringCctvListContextProvider } from '@/context/tables/MonitoringListContext'
import { CctvListContextProvider } from '@/context/tables/CctvListContext'
import { InstallationPassivePartStatusListContextProvider } from '@/context/installationPassivePartStatusListContext'
import { typedObjectEntries } from '@/utils/object'
import ExploitationIncidents from '@/components/exploitation/ExploitationIncidents'
import { PecListPageContent } from '@/components/audit/PecListPageContent'
import { PecListContextProvider } from '@/context/tables/PecListContext'
import PecPageContent from '@/components/audit/Folder/PecPageContent'
import { UserPermissions } from '@/config/userPermissions'
import { ServiceStatusListContextProvider } from '@/context/statuses/ServiceStatusListContext'
import { PecStatusFieldListContextProviderWrapper } from '@/context/entityFields/PecStatusFieldListContext'
import { PecStatusListContextProvider } from '@/context/statuses/PecStatusListContext'
import ExportPecFiles from '@/components/audit/ExportPecFiles'


export default function UserRouter() {
  const { hasPermission, user } = useAuthContext()

  const redirectRoute = typedObjectEntries({
    projectRead: routes.projects,
    communicationRead: routes.communication,
    channelRead: routes.channels,
    exploitationRead: routes.exploitation,
    auditRead: routes.auditProjects,
    catalogRead: routes.catalogs,
  } satisfies Partial<Record<keyof typeof UserPermissions, ValueOf<typeof routes> & string>>)
    .find(([permissionCode, route]) => hasPermission(permissionCode))?.[1] ?? '/404'

  return (
    <Routes>
      <Route element={<UserPageContainer />}>

        {/* Раздел "Проекты" */}
        {hasPermission('projectRead') &&
          <Route path={routes.projects} element={
            <CctvChannelStatusListContextProvider>
              <CctvInstallationStatusListContextProvider>
                <CctvAcceptanceStatusListContextProvider>
                  <IdAcceptanceStatusListContextProvider>
                    <InstallationPassivePartStatusListContextProvider>
                      <ProjectListContextProvider>
                        <TabsContextProvider />
                      </ProjectListContextProvider>
                    </InstallationPassivePartStatusListContextProvider>
                  </IdAcceptanceStatusListContextProvider>
                </CctvAcceptanceStatusListContextProvider>
              </CctvInstallationStatusListContextProvider>
            </CctvChannelStatusListContextProvider>
          }>
            <Route index element={<ProjectListPageContent />} />
            <Route path={routes.projectCreate} element={<CreateProjectPageContent />} />
            <Route path={routes.project(':projectId')} element={<ProjectContextProvider />}>
              <Route index element={
                <CctvListContextProvider>
                  <DashBoard />
                </CctvListContextProvider>
              } />
              <Route path={routes.projectEdit(':projectId')} element={<EditProjectPageContent />} />
              <Route path={routes.projectCctvs(':projectId')} element={
                <CctvListContextProvider>
                  <ProjectFieldListContextProviderWrapper />
                </CctvListContextProvider>
              }>
                <Route index element={<CCTVListPageContent />} />
                <Route path={routes.projectCctv(':projectId', ':cctvId')} element={<Cctv />} />
              </Route>

              {typedObjectEntries(projectReportApiCodes).map(([code, apiCode]) =>
                <Route key={code} path={routes.projectReport(':projectId', apiCode)} element={<Report code={code} />} />  
              )}

              <Route path={routes.projectMonitoring(':projectId')} element={
                <MonitoringCctvListContextProvider user={user}>
                  <MonitoringCctvTable />
                </MonitoringCctvListContextProvider>
              } />
              <Route path={routes.projectMonitoringMap(':projectId')} element={<MonitoringCctvMap />} />
            </Route>
          </Route>
        }
        
        {/* Раздел "Связь" */}
        {hasPermission('communicationRead') &&
          <Route path={routes.communication} element={
              <ChannelListContextProvider>
                <ServiceListContextProvider>
                  <ConstructionObjectListContextProvider>
                    <BillingCommonListContextProvider>
                      <BillingRevenueListContextProvider>
                        <BillingExpenseListContextProvider>
                          <IdAcceptanceStatusListContextProvider>
                            <TabsContextProvider />
                          </IdAcceptanceStatusListContextProvider>
                        </BillingExpenseListContextProvider>
                      </BillingRevenueListContextProvider>
                    </BillingCommonListContextProvider>
                  </ConstructionObjectListContextProvider>
                </ServiceListContextProvider>
              </ChannelListContextProvider>
          }>
            <Route index element={<CommunicationPageContent />} />
            {hasPermission('serviceRead') &&
              <Route path={routes.services} element={<ChannelStatusListContextProvider />}>
                <Route index element={<ServiceTablePageContent />} />
                <Route path={routes.service(':serviceId')} element={
                  <ServiceStatusListContextProvider>
                    <ServicePageContent />
                  </ServiceStatusListContextProvider>
                } />
              </Route>
            }
            {hasPermission('constructionObjectRead') &&
              <Route path={routes.constructionObjects} element={<ConstructionObjectTablePageContent />} />
            }
            {hasPermission('serviceBillingRead') &&
              <>
                <Route path={routes.billingCommon} element={<BillingCommonTablePageContent />} />
                <Route path={routes.billingRevenue} element={<BillingRevenueTablePageContent />} />
                <Route
                  path={routes.billingExpenses}
                  element={<BillingExpenseTablePageContent serviceTypeKindName={ServiceTypeKindNames.basic} />}
                />
                <Route
                  path={routes.billingPortExpenses}
                  element={<BillingExpenseTablePageContent serviceTypeKindName={ServiceTypeKindNames.port} />}
                />
              </>
            }
            {hasPermission('exportActs') &&
              <Route path={routes.actFormation} element={<ActFormationTableContent />} />
            }
          </Route>
        }

        {/* Раздел "Каналы" */}
        {hasPermission('channelRead') &&
        <Route path={routes.channels} element={
            <ChannelListContextProvider>
              <AddressListContextProvider>
                <ChannelPaymentsListContextProvider>
                  <TabsContextProvider />
                </ChannelPaymentsListContextProvider>
              </AddressListContextProvider>
            </ChannelListContextProvider>
          }>
            <Route path={routes.channels} element={
              <ChannelStatusListContextProvider>
                <ChannelFieldListContextProvider />
              </ChannelStatusListContextProvider>
            }>
              <Route index element={<ChannelTablePageContent />} />
              <Route path={routes.channel(':channelId')} element={<ChannelPageContent />} />
            </Route>
            <Route path={routes.addresses} element={<AddressTablePageContent />} />
            <Route path={routes.address(':addressId')} element={<AddressPageContent />} />
            <Route path={routes.channelPayments} element={<ChannelPaymentsTablePageContent />} />
          </Route>
        }

        {hasPermission('exploitationRead') &&
          <Route path={routes.exploitation} element={
            <TabsContextProvider />
          }>
            <Route index element={
              <CctvListContextProvider>
                <DashBoard />
              </CctvListContextProvider>
            } />
            <Route path={routes.exploitationCctvs} element={
              <CctvListContextProvider />
            }>
              <Route index element={<CCTVListPageContent />} />
              <Route path={routes.exploitationCctv(':cctvId')} element={
                <CctvChannelStatusListContextProvider>
                  <CctvInstallationStatusListContextProvider>
                    <CctvAcceptanceStatusListContextProvider>
                      <IdAcceptanceStatusListContextProvider>
                        <InstallationPassivePartStatusListContextProvider>
                          <Cctv />
                        </InstallationPassivePartStatusListContextProvider>
                      </IdAcceptanceStatusListContextProvider>
                    </CctvAcceptanceStatusListContextProvider>
                  </CctvInstallationStatusListContextProvider>
                </CctvChannelStatusListContextProvider>
              } />
            </Route>

            {typedObjectEntries(exploitationReportApiCodes).map(([code, apiCode]) =>
              <Route key={code} path={routes.exploitationReport(apiCode)} element={<Report code={code} />} />,
            )}

            <Route path={routes.exploitationMonitoring} element={
              <MonitoringCctvListContextProvider user={user}>
                <MonitoringCctvTable />
              </MonitoringCctvListContextProvider>
            } />
            <Route path={routes.exploitationMonitoringMap} element={<MonitoringCctvMap />} />
            <Route path={routes.exploitationIncidents} element={<ExploitationIncidents />} />
          </Route>
        }

        {hasPermission('auditRead') &&
          <Route path={routes.auditProjects} element={
            <ProjectListContextProvider isAudit>
              <TabsContextProvider />
            </ProjectListContextProvider>
          }>
            <Route index element={<ProjectListPageContent isAudit />} />
            <Route path={routes.auditProjectCreate} element={<CreateProjectPageContent isAudit />} />
            <Route path={routes.auditProject(':projectId')} element={<ProjectContextProvider />}>
              <Route element={<PecStatusFieldListContextProviderWrapper />}>
                <Route element={<PecStatusListContextProvider />}>
                  <Route index element={
                    <PecListContextProvider>
                      <PecListPageContent />
                    </PecListContextProvider>
                  } />
                  <Route path={routes.auditProjectPec(':projectId', ':pecId')} element={<PecPageContent />} />
                </Route>
                {hasPermission('auditProjectPecFilesExport') &&
                  <Route path={routes.auditExportFiles(':projectId')} element={<ExportPecFiles />} />
                }
              </Route>
              <Route path={routes.auditProjectEdit(':projectId')} element={<EditProjectPageContent isAudit />} />
            </Route>
          </Route>
        }

        {hasPermission('catalogRead') &&
          <Route path={routes.catalogs}>
            {/* Список справочников */}
            <Route index element={<CatalogListPageContent />} />

            {/* Справочник API ресурса */}
            <Route path={routes.catalog(':apiResource')} element={<CatalogPageContainer />}>
              <Route index element={<RecordListPageContent />} />
              <Route path='create' element={<CreateRecordPageContent />} />
              <Route path=':recordId'>
                <Route index element={<RecordPageContent />} />
                <Route path='edit' element={<EditRecordPageContent />} />
              </Route>
            </Route>
          </Route>
        }

        {/* Редирект */}
        <Route path='/' element={<Navigate to={redirectRoute} />} />
        <Route path={routes.signIn} element={<Navigate to={redirectRoute} />} />
        <Route path={routes.signOut} element={<Navigate to={redirectRoute} />} />

        {/* Ошибка 404 */}
        <Route path='*' element={<Error404PageContent />} />
      </Route>
    </Routes>
  )
}
