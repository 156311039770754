import { useCallback } from 'react'
import { useModal } from '@/context/ModalContext'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import IntervalTimer, { Props as IntervalTimerProps } from '@/components/common/time/IntervalTimer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'
import ExportBtn from '@/components/common/btns/ExportBtn'
import { incidentCreateKnsModal } from '@/components/modals/incidents/modals'
import { UserRole } from '@/config/roles'
import PermissionRequired from '@/components/auth/PermissionRequired'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = Pick<IntervalTimerProps, 'onTimerInterval'> & {
  recordListLength?: number
  recordListCount?: number
  onReload: () => void
  clearFilter: () => void
  exportApiRoute?: string
  exportBtnLabel?: string
  projectId?: string
  userRole: UserRole
}


/** Таблица мониторинга ТВН */
export default function MonitoringCctvTableToolbar({
  recordListLength,
  recordListCount,
  onReload,
  clearFilter,
  onTimerInterval,
  exportBtnLabel,
  exportApiRoute,
  projectId,
}: Props) {
	const { showModalDialog } = useModal()
  const { user } = useAuthContext()

  const { isIncidentKns } = user || {}


	/** Показать справку по фильтрации ТВН */
	const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('мониторинга'))
	}, [showModalDialog])

  const handleCreateIncident = useCallback(() => {
    showModalDialog(incidentCreateKnsModal(projectId));
  }, [showModalDialog, exportApiRoute]);

	return (
		<div style={{ width: '100%', height: '48px' }}>
			<div
        className='container-fluid py-3'
        style={{ position: 'fixed', height: '100px', background: 'white', marginTop: '-8px' }}
      >
				<div className='btn-toolbar gap-2 align-items-center' >
					<IntervalTimer onTimerInterval={onTimerInterval} sessionStorageKey='monitoringTableReloadIntervalInMS' />
					<button
						className='btn btn-sm btn-outline-primary'
						onClick={clearFilter}>
						<FontAwesomeIcon icon={faEraser} className='me-2' />
						Сбросить фильтр
					</button>
          <div>
            Показано {recordListLength} {!!recordListCount && `из ${recordListCount}`} записей.
          </div>
         {exportApiRoute &&
          <div className='container-fluid bg-white' style={{ width: 800, marginRight: -22 }}>
            <div className='container-fluid d-flex justify-content-end'>
              {exportApiRoute && <ExportBtn label={exportBtnLabel} apiRoute={exportApiRoute} />}
             
             </div>
           </div>
         }
         <PermissionRequired permission='pecKns'>
         {isIncidentKns && (
              <button
                className='btn btn-sm btn-outline-primary text-nowrap ms-2'
                onClick={handleCreateIncident}
              >
                     Создать инцидент по КНС
              </button>
              )}
         </PermissionRequired>
					<button
						className='btn btn-link p-0 ms-auto'
						onClick={handleShowFilterInfo}>
						<FontAwesomeIcon icon={faInfoCircle} className='mx-2' />
						Справка
					</button>
          <ReloadBtn onClick={onReload} />
				</div>
			</div>
		</div>
	)
}