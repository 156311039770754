import { IIncidentBase, IMonitoringCctv } from '@/context/types'
import { TableApiResourceContextValue } from '@/context/tableApiResource/TableApiResourceContext'
import IncidentLink from '@/components/modals/incidents/IncidentLink'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUmbrella } from '@fortawesome/free-solid-svg-icons'
import Stopwatch from '@/components/common/time/Stopwatch'


type Props = {
  incidents: IMonitoringCctv['incidents']
  incidentsByCctvs: IIncidentBase[]
  onUpdate: TableApiResourceContextValue<any, any>['loadRecordList']
}

const MonitoringIncidentList = ({ incidents = [], incidentsByCctvs = [], onUpdate }: Props) => {
  const { projectId } = useParams();

  // Обрабатываем инциденты
  const allIncidents = [
    ...incidents.map(({ incident }) => ({
      ...incident,
      source: 'incidents'
    })),
    ...incidentsByCctvs.map((incidentByCctv) => ({
      ...incidentByCctv,
      source: 'incidentsByCctvs'
    }))
  ];

  if (!allIncidents.length) {
    return <>Нет</>;
  }

  return (
    <>
      Есть:
      {allIncidents.map((incident, index) => (
        <div key={`${incident.id}-${incident.source}-${index}`} className='text-nowrap'>
          <IncidentLink 
            projectId={projectId} 
            incidentId={incident.id} 
            onUpdate={onUpdate} 
          />
          {!!incident.children?.length && (
            <>
              {' '}
              <FontAwesomeIcon icon={faUmbrella} />
            </>
          )}
          {' ('}
          {incident.origin
            ? incident.origin.name
            : incident.createdByUser ? 'Польз.' : 'СМО'
          }
          {'). '}
          {incident.hardware?.type?.tag}
          {': '}
          {incident.stage?.name}
          {': '}
          <span className='text-muted'>
            <Stopwatch initialDatetime={typeof incident.updatedDatetime === 'string' ? new Date(incident.updatedDatetime) : incident.updatedDatetime} />
          </span>
        </div>
      ))}
    </>
  );
}

export default MonitoringIncidentList
