import { useEffect, useMemo } from 'react'
import {
  ProjectsMonitoringCctvTableColumnName, ProjectsMonitoringCctvTableColumnNameList, ProjectsMonitoringCctvTableColumns,
  prepareProjectsMonitoringCctvAdditionalCols,
} from '@/config/tables/lists/projects/projectsMonitoringCctv'
import { isObjectEmpty } from '@/utils/tools'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import { useMonitoringCctvListContext } from '@/context/tables/MonitoringListContext'
import TableHead from '@/components/common/table/TableHead'
import TableRow from '@/components/common/table/TableRow'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import Loader from '@/components/common/Loader'
import MonitoringCctvTableToolbar from '@/components/ProjectsContent/monitoring/table/MonitoringCctvTableToolbar'
import PaginationWSP from '@/components/common/PaginationWSP'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { apiRoutes } from '@/config/routes'
import { useParams } from 'react-router-dom'
import ProjectHeader from '@/components/ProjectsContent/ProjectHeader'
import { useTabsContext } from '@/context/TabsContext'
import { CctvHardwareKey, cctvHardwareKeys } from '@/config/hardware'
import { IMonitoringCctv } from '@/context/types'
import MonitoringIncidentList from '@/components/CctvListPageContent/common/MonitoringIncidentList'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'
import ExploitationHeader from '@/components/exploitation/ExploitationHeader'
import {
  ExploitationMonitoringCctvTableColumnNameList, ExploitationMonitoringCctvTableColumns,
} from '@/config/tables/lists/exploitation/exploitationMonitoringCctv'
import { UserRole } from '@/config/roles'


/** Таблица мониторинга ТВН */
export default function MonitoringCctvTable() {
	useDocumentTitle('Мониторинг ТВН')
	const { projectId } = useParams()
	const availableCols = projectId 
    // eslint-disable-next-line react-hooks/rules-of-hooks
		? useTableAvailableCols(ProjectsMonitoringCctvTableColumnNameList, ProjectsMonitoringCctvTableColumns)
    // eslint-disable-next-line react-hooks/rules-of-hooks
		: useTableAvailableCols(ExploitationMonitoringCctvTableColumnNameList, ExploitationMonitoringCctvTableColumns)

	const {
		recordList,
		count,
		page,
		pageCount,
		filter,
		isRecordListLoading,
		isFullIdRecordListLoading,
		loadRecordList,
		setRecordListPage,
		setRecordListFilter,
	} = useMonitoringCctvListContext()
  
  useEffect(() => {
    loadRecordList()
  }, [loadRecordList])

	const {
		filterForm,
		updateFilterForm,
		applyFilter,
		clearFilter
	} = useTableFilterForm(filter, setRecordListFilter)
  const { setLabel } = useTabsContext()

  setLabel('Мониторинг', faHeart)

	const maxHardwareCount = useMemo(() => {
		const maxHardwareCount = cctvHardwareKeys.reduce((acc, code) => {
			acc[code] = 0
			return acc
		}, {} as Record<CctvHardwareKey, number>)

		for (const cctv of recordList)
			for (const hardwareKey of cctvHardwareKeys)
				if (cctv[hardwareKey].length > maxHardwareCount[hardwareKey])
					maxHardwareCount[hardwareKey] = cctv[hardwareKey].length

		return maxHardwareCount
	}, [recordList])

	const isLoading = isRecordListLoading || isFullIdRecordListLoading

  //todo-sem костыльное переписывание value чтобы использовать loadRecordList, нужно переписать по нормальному
  const columns: typeof ProjectsMonitoringCctvTableColumns | typeof ExploitationMonitoringCctvTableColumns = {
    ...(projectId ? ProjectsMonitoringCctvTableColumns : ExploitationMonitoringCctvTableColumns),
    incidents: {
      ...(projectId ? ProjectsMonitoringCctvTableColumns : ExploitationMonitoringCctvTableColumns).incidents,
      value: cctv => <MonitoringIncidentList incidents={cctv.incidents} incidentsByCctvs={cctv.incidentsByCctvs} onUpdate={loadRecordList}/>
    },
  }

	return (
		<>
      {projectId ? <ProjectHeader /> : <ExploitationHeader />}
			<div className='mt-2'>
				<MonitoringCctvTableToolbar
                  recordListLength={recordList.length}
                  recordListCount={count}
                  onReload={loadRecordList}
                  clearFilter={clearFilter}
                  onTimerInterval={loadRecordList}
                  exportBtnLabel='Массовый экспорт инцидентов'
                  exportApiRoute={
                    projectId ? apiRoutes.exportProjectIncidents(projectId) : apiRoutes.exportExploitationIncidents
                  }
				  projectId={projectId}
				  userRole={UserRole.kns}
            />
				<div className='container-fluid'>
					<table className='table table-sm table-bordered table-hover' style={{ height: 1 }}>
						{!isLoading &&
							<>
								<TableHead
									columnNameList={availableCols as any /* //todo-sem передавать столбцы нужного типа через пропсы? */}
									additionalColumns={recordList.length ? prepareProjectsMonitoringCctvAdditionalCols(recordList[0], maxHardwareCount) : []}
									columns={columns}
									filterForm={filterForm}
									updateFilterForm={updateFilterForm}
									applyFilter={applyFilter}
									top={233}
								/>
								<tbody>
									{!recordList.length
										? <EmptyTableRow columnCount={availableCols.length}>
											{isObjectEmpty(filter) ? 'Записей нет нет' : 'Записей не найдено'}
										</EmptyTableRow>
										: recordList.map(cctv =>
											<TableRow<IMonitoringCctv, ProjectsMonitoringCctvTableColumnName>
												key={cctv.project.id + cctv.number}
												columnNameList={availableCols as any /* //todo-sem */}
												columns={columns}
												additionalColumns={prepareProjectsMonitoringCctvAdditionalCols(cctv, maxHardwareCount)}
												record={cctv}
											/>,
										)
									}
								</tbody>
							</>
						}
					</table>
					{isLoading && <Loader />}
					<div style={{ position: 'sticky', bottom: 0, marginLeft: 12 }}>
						<PaginationWSP
							currentPage={page}
							setCurrentPage={setRecordListPage}
							lastPage={pageCount} />
					</div>
				</div>
			</div>
		</>
	)
}