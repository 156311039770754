import { useCallback } from 'react'
import { IIncident, IProject } from '@/context/types'
import { useModal } from '@/context/ModalContext'
import { incidentDetailModal } from '@/components/modals/incidents/modals'


type Props = {
	projectId?: IProject['id']
	incidentId: IIncident['id'] 
	onUpdate: () => void
}


/** Ссылка на инцидент */
export default function IncidentLink({ projectId, incidentId, onUpdate }: Props) {
	const { showModalDialog } = useModal()

	const handleClick = useCallback(() => {
		showModalDialog(incidentDetailModal(projectId, incidentId, onUpdate))
	}, [incidentId, onUpdate, showModalDialog, projectId])

	return (
		<a onClick={handleClick} className='' role='button'>№&nbsp;{incidentId}</a>
	)
}