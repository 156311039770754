import { useMemo, useState } from 'react';
import { useModal } from '@/context/ModalContext';
import { instanceAxios as axios } from '@/api/instanceAxios';
import { useFormContext } from '@/context/form/FormContext';
import TextAreaInput from '@/components/common/form/TextAreaInput';
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect';
import { ApiResource, ICctv, IIncident, IIncidentKnsCreateForm, ISelectOption } from '@/context/types';
import { getApiResourceCreateRoute } from '@/config/routes';
import getErrorMessage from '@/utils/getErrorMessage';

type Props = {
    apiRoute?: string;
    hardware?: ICctv;
    onCreate?: () => void;
}

// Тип для фильтра
type ApiResourceFilter = {
    'project.id'?: string
}

function cctvRecordToOption(record: ICctv): ISelectOption {
    return {
        label: `${record.number} (${record.type.name})`,  // Отображаем номер УИК и тип
        value: record.id,                                 // Значение по умолчанию - ID
    };
}

export default function IncidentCreateKnsForm({ apiRoute, hardware, onCreate }: Props) {
    const { showModalDialog, usePrimaryCallback } = useModal();
    const { form, setError } = useFormContext<IIncidentKnsCreateForm>();
    const [isLoading, setIsLoading] = useState(false);

   
      
    const filter = useMemo<ApiResourceFilter | undefined>(() => {
        if (!apiRoute) return undefined;
        return {
            'project.id': apiRoute,
        };
    }, [apiRoute]);

    usePrimaryCallback(async () => {
        if (!form.cctv) setError('cctv', 'Выберите Номер ТВН');
        if (!form.stage) setError('stage', 'Выберите этап');
        if (!form.description) setError('description', 'Введите комментарий');
        if (!form.origin) setError('origin');
        if (!form.stage || !form.description) return false;

        try {
            setIsLoading(true);
            await axios.post(
                getApiResourceCreateRoute(ApiResource.Incident),
                {
                    ...form,
                    origin: 'kns',
                    hardware: hardware?.id
                }
                
            );
            if (onCreate) {
                onCreate()
            }
            window.location.reload();
        } catch (e: any) {
            showModalDialog({
                type: 'error',
                header: 'Ошибка',
                content: await getErrorMessage(e),
            });
        } finally {
            setIsLoading(false);
        }
    }, [form, hardware, onCreate, showModalDialog])

    return (
        <>
            <ApiResourceSelect<ICctv, IIncidentKnsCreateForm>
                apiResource={ApiResource.Cctv}
                filter={filter}
                label="Номер ТВН"
                name="cctv"
                required
                placeholder="Введите номер ТВН"
                disabled={isLoading}
                recordToOption={cctvRecordToOption}
                async         // запрос по search
                asyncNumber   // запрос по number
            />
            <ApiResourceSelect<NonNullable<IIncident['stage']>, IIncidentKnsCreateForm>
                apiResource={ApiResource.IncidentStage}
                label="Этап"
                name="stage"
                required
                disabled={isLoading}
            />
            <TextAreaInput<IIncidentKnsCreateForm>
                label="Комментарий"
                name="description"
                required
                disabled={isLoading}
            />
        </>
    );
}
