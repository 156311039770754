import { useCallback, useEffect, useId, useState } from 'react'
import Select, { GroupBase, MultiValue, SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import { ApiResource, ApiResourceFilter, IRecord, ISelectOption } from '@/context/types'
import defaultRecordToOption, { defaultFilterOption } from '@/utils/recordToSelectOption'
import {
  ApiResourceOptionListContextProvider, useApiResourceOptionListContext,
} from '@/context/form/ApiResourceSelectOptionListContext'
import InputGroup from '@/components/common/form/InputGroup'
import { useFormContext } from '@/context/form/FormContext'
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync'
import debounce from 'debounce'


type Props<TRecord extends IRecord, TForm> = {
  label: string
  name: keyof TForm
  initValue?: TRecord | TRecord[] | null
  placeholder?: string
  required?: boolean
  helpText?: string
  disabled?: boolean
  apiResource: ApiResource
  async?: boolean
  asyncNumber?: boolean
  isMulti?: boolean
  filter?: ApiResourceFilter
  recordToOption?: (record: TRecord) => ISelectOption
}

// Фикс бага Typescript с Array.isArray(array: readonly Array<any>)
declare global {
  interface ArrayConstructor {
    isArray(arg: ReadonlyArray<any> | any): arg is ReadonlyArray<any>
  }
}

type AsyncSelectAdditionalProps = NonNullable<
  AsyncAdditionalProps<ISelectOption, GroupBase<ISelectOption>>['loadOptions']
>

export default function ApiResourceSelect<TRecord extends IRecord, TForm>({
  label,
  name,
  initValue,
  placeholder,
  required,
  helpText,
  disabled,
  apiResource,
  async = false,
  asyncNumber = false,
  isMulti = false,
  filter,
  recordToOption = defaultRecordToOption,
}: Props<TRecord, TForm>) {
  const { setField, errors } = useFormContext<TForm>()
  const id = useId()
  const [option, setOption] = useState<ISelectOption | readonly ISelectOption[] | null>(null)

  useEffect(() => {
    if (Array.isArray(initValue)) {
      setOption(initValue.map((subValue: TRecord) => recordToOption(subValue)))
      setField(name, initValue.map((subValue: TRecord) => recordToOption(subValue).value))
    } else {
      // eslint-disable-next-line eqeqeq
      setOption(initValue != undefined ? recordToOption(initValue) : null)
      // eslint-disable-next-line eqeqeq
      setField(name, initValue != undefined ? recordToOption(initValue).value : initValue)
    }
  }, [name, initValue, recordToOption, setField])

  const handleChange = useCallback((option: ISelectOption | readonly ISelectOption[] | null) => {
    setOption(option)
    if (Array.isArray(option))
      setField(name, option.map((option: ISelectOption) => option.value))
    else
      setField(name, option ? option.value : null)
  }, [name, setField])

  return (
    <ApiResourceOptionListContextProvider
      apiResource={apiResource}
      filter={filter}
      recordToOption={recordToOption}
      load={!async}>
      <InputGroup
        id={id}
        label={label}
        required={required}
        helpText={helpText}
        invalidMessage={errors[name]}>
        {async
          ? asyncNumber
            ? <AsyncResourceNumberSelect<TForm>
                id={id}
                name={name}
                value={option}
                isMulti={isMulti}
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleChange}
              />
            : <AsyncResourceSelect<TForm>
                id={id}
                name={name}
                value={option}
                isMulti={isMulti}
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleChange}
              />
          : <SyncResourceSelect<TForm>
              id={id}
              name={name}
              value={option}
              isMulti={isMulti}
              placeholder={placeholder}
              disabled={disabled}
              onChange={handleChange}
            />
        }
      </InputGroup>
    </ApiResourceOptionListContextProvider>
  )
}

type InnerResourceSelectProps<TForm> = {
  id: string
  name: keyof TForm
  value: ISelectOption | readonly ISelectOption[] | null
  isMulti?: boolean
  placeholder?: string
  disabled?: boolean
  onChange: (option: SingleValue<ISelectOption> | MultiValue<ISelectOption>) => void
}

function SyncResourceSelect<TForm>({
  id,
  name,
  value,
  isMulti,
  placeholder = '',
  disabled,
  onChange,
}: InnerResourceSelectProps<TForm>) {
  const { errors } = useFormContext<TForm>()
  const { isLoading, selectOptionList: optionList } = useApiResourceOptionListContext()

  return (
    <Select
      className={errors[name] && 'is-invalid rounded border border-danger'}
      id={id}
      value={value}
      isMulti={isMulti}
      placeholder={isLoading ? 'Загрузка...' : placeholder}
      options={optionList}
      filterOption={defaultFilterOption}
      isClearable={true}
      isDisabled={disabled || isLoading}
      onChange={onChange}
    />
  )
}

function AsyncResourceSelect<TForm>({
  id,
  name,
  value,
  isMulti,
  placeholder = '',
  disabled,
  onChange,
}: InnerResourceSelectProps<TForm>) {
  const { errors } = useFormContext<TForm>()
  const [hasFocus, setHasFocus] = useState(false)
  const {
    selectOptionList: optionList,
    searchApiResourceOptionlist: searchSelectOptionlist
  } = useApiResourceOptionListContext()

  const handleLoadOptions = debounce(useCallback<AsyncSelectAdditionalProps>((search, setOptions) => {
    searchSelectOptionlist(search).then(setOptions)
  }, [searchSelectOptionlist]), 800)

  return (
    <AsyncSelect
      className={errors[name] && 'is-invalid rounded border border-danger'}
      id={id}
      value={value}
      isMulti={isMulti}
      placeholder={hasFocus ? 'Начните ввод' : placeholder}
      defaultOptions={optionList}
      loadOptions={handleLoadOptions}
      loadingMessage={() => 'Поиск...'}
      noOptionsMessage={() => 'Ничего не найдено'}
      isClearable={true}
      isDisabled={disabled}
      onChange={onChange}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
    />
  )
}

function AsyncResourceNumberSelect<TForm>({
  id,
  name,
  value,
  isMulti,
  placeholder = '',
  disabled,
  onChange,
}: InnerResourceSelectProps<TForm>) {
  const { errors } = useFormContext<TForm>()
  const [hasFocus, setHasFocus] = useState(false)
  const {
    selectOptionList: optionList,
    numberApiResourceOptionlist: numberSelectOptionlist
  } = useApiResourceOptionListContext()

  const handleLoadOptions = debounce(useCallback<AsyncSelectAdditionalProps>((number, setOptions) => {
    numberSelectOptionlist(number).then(setOptions)
  }, [numberSelectOptionlist]), 800)

  return (
    <AsyncSelect
      className={errors[name] && 'is-invalid rounded border border-danger'}
      id={id}
      value={value}
      isMulti={isMulti}
      placeholder={hasFocus ? 'Начните ввод' : placeholder}
      defaultOptions={optionList}
      loadOptions={handleLoadOptions}
      loadingMessage={() => 'Поиск...'}
      noOptionsMessage={() => 'Ничего не найдено'}
      isClearable={true}
      isDisabled={disabled}
      onChange={onChange}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
    />
  )
}
