import { createContext, PropsWithChildren, useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ApiResource, IMonitoringCctv, IUser } from '@/context/types';
import {
  ProjectsMonitoringCctvTableColumnName, ProjectsMonitoringCctvTableColumns,
} from '@/config/tables/lists/projects/projectsMonitoringCctv';
import { ExploitationMonitoringCctvTableColumns } from '@/config/tables/lists/exploitation/exploitationMonitoringCctv';
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext';
import { getCctvsListFilter } from '@/config/listFilters';

/** MonitoringCctv list context */
const MonitoringCctvListContext = createContext({} as TableApiResourceContextValue<IMonitoringCctv, ProjectsMonitoringCctvTableColumnName>);

/** MonitoringCctv list context hook */
export const useMonitoringCctvListContext = () => useContext(MonitoringCctvListContext);

type Props = PropsWithChildren<{
  user?: IUser
}>;

/** MonitoringCctv list context provider */
export function MonitoringCctvListContextProvider({ children, user }: Props) {
  const { projectId } = useParams();


  type GroupType = `${string}:read`;

  const groups: GroupType[] = [
    'id:read',
    'monitoring:read',
    ...(user?.isIncidentKns ? ['kns:read'] as GroupType[] : []),
  ];

  return (
    <TableApiResourceContextProvider<IMonitoringCctv, ProjectsMonitoringCctvTableColumnName>
      Context={MonitoringCctvListContext}
      apiResource={ApiResource.MonitoringCctv}
      recordIdKey='monitoringCctvId'
      tableColumns={projectId ? ProjectsMonitoringCctvTableColumns : ExploitationMonitoringCctvTableColumns}
      logName='monitoringCctvList'
      groups={groups}
      filter={getCctvsListFilter(projectId)}
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  );
}
