import { ApiResource, Hardware, ICctv, IIncident, IProject } from '@/context/types'
import { getHardwareLabel } from '@/utils/hardware'
import { ShowModalDialogParams } from '@/context/ModalContext'
import { ApiResourceContextProvider } from '@/context/apiResource/ApiResourceContext'
import { FormContextProvider } from '@/context/form/FormContext'
import IncidentCloseForm from '@/components/modals/incidents/IncidentCloseForm'
import IncidentCommentForm from '@/components/modals/incidents/IncidentCommentForm'
import IncidentCreateForm from '@/components/modals/incidents/IncidentCreateForm'
import IncidentDetailModalContent from '@/components/modals/incidents/IncidentDetailModalContent'
import IncidentTable from '@/components/modals/incidents/IncidentTable'
import IncidentUpdateForm from '@/components/modals/incidents/IncidentUpdateForm'
import IncidentCreateKnsForm from './incidentCreateKnsFrom'


/** Окно со списком инцидентов оборудования */
export function hardwareIncidentListModal(projectId: IProject['id'] | undefined, hardware: Hardware, onUpdate: () => void): ShowModalDialogParams {
	const fromDate = new Date(Date.now() - 1000 * 60 * 60 * 24 * 90)
	const incidentFilter = {
		'hardware.id': hardware.id.toString(),
		'createdDatetime[after]': fromDate.toISOString(),
	}

	return {
		type: 'close',
		size: 'xl',
		header: `${getHardwareLabel(hardware)}: Инциденты за последние 90 дней`,
		content:
			<ApiResourceContextProvider
				apiResource={ApiResource.Incident}
				filter={incidentFilter}>
				<IncidentTable projectId={projectId} onUpdate={onUpdate} />
			</ApiResourceContextProvider>
	}
}

/** Окно деталей инцидента */
export function incidentDetailModal(
  projectId: IProject['id'] | undefined,
  incidentId: number,
  onUpdate: () => void
): ShowModalDialogParams {
	return {
		type: 'close',
		size: 'xl',
		header: `Инцидент № ${incidentId}`,
		content:
			<IncidentDetailModalContent
				projectId={projectId}
				incidentId={incidentId}
				onUpdate={onUpdate}
      />
	}
}

/** Окно создания инцидента */
export function incidentCreateModal(hardware: Hardware, onCreate: () => void): ShowModalDialogParams {
	return {
		type: 'okCancel',
		dismissable: false,
		size: 'lg',
		header: `${getHardwareLabel(hardware)}: Открыть инцидент`,
		content:
			<FormContextProvider>
				<IncidentCreateForm
					hardware={hardware}
					onCreate={onCreate} />
			</FormContextProvider>
	}
}

/** Окно создания инцидента КНС*/
export function incidentCreateKnsModal(projectId?: string, hardware?: ICctv, onCreate?: () => void): ShowModalDialogParams {
	return {
		type: 'okCancel',
		dismissable: false,
		size: 'lg',
		header: `Открыть инцидент`,
		content:
			<FormContextProvider>
				<IncidentCreateKnsForm
				    apiRoute={projectId}
					hardware={hardware}
					onCreate={onCreate}/>
			</FormContextProvider>
	}
}

/** Окно редактирования инцидента */
export function incidentUpdateModal(incidentId: number, onDone: () => void, onCancel: () => void): ShowModalDialogParams {
	return {
		type: 'saveCancel',
		dismissable: false,
		size: 'lg',
		header: `Инцидент № ${incidentId}: Изменить этап`,
		content:
			<ApiResourceContextProvider
				apiResource={ApiResource.Incident}
				recordId={incidentId}
				loadList={false}>
				<FormContextProvider>
					<IncidentUpdateForm
						onDone={onDone}
						onCancel={onCancel} />
				</FormContextProvider>
			</ApiResourceContextProvider >
	}
}

/** Окно закрытия инцидента */
export function incidentCloseModal(incidentId: number, onDone: () => void, onCancel: () => void): ShowModalDialogParams {
	return {
		type: 'okCancel',
		dismissable: false,
		size: 'lg',
		header: `Инцидент № ${incidentId}: Закрыть`,
		content:
			<FormContextProvider>
				<IncidentCloseForm
					incidentId={incidentId}
					onDone={onDone}
					onCancel={onCancel} />
			</FormContextProvider>
	}
}

/** Окно добавления комментария к инциденту */
export function incidentCommentModal(incident: IIncident, onDone: () => void, onCancel: () => void): ShowModalDialogParams {
	return {
		type: 'saveCancel',
		dismissable: false,
		header: `Инцидент № ${incident.id}: Добавить комментарий`,
		content:
			<FormContextProvider>
				<IncidentCommentForm
					incident={incident}
					onDone={onDone}
					onCancel={onCancel} />
			</FormContextProvider>
	}
}