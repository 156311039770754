import { useAuthContext } from '@/context/auth/AuthContext'
import { IRecordWithId, TableColumnConfig } from '@/context/types'


const useTableAvailableCols = <TRecord extends IRecordWithId, TColumnName extends string>(
  columnNameList: readonly TColumnName[],
  columns: Record<TColumnName, TableColumnConfig<TRecord>>,
) => {
  const { hasPermission, user } = useAuthContext()

  return columnNameList.filter(colName => {
    const column = columns[colName];
    const isIncidentKnsRequired = column.incidentKns ?? false

    return !column.hidden &&
      (!column.permissions || hasPermission(column.permissions)) &&
      (!isIncidentKnsRequired || user?.isIncidentKns)
  })
}

export default useTableAvailableCols
