import { useState } from 'react'
import { ApiResource, IOrganization, IPec, IPecStatusField } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import Loader from '@/components/common/Loader'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import { patchPecContractor } from '@/api/queries/audit'


type Props = {
  pec: IPec
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>
}

type Form = {
  contractor: IOrganization['id']
}

export default function PecEditContractorForm({ pec, statusFieldId, reloadCard }: Props) {
  const { form } = useFormContext<Form>()
  const { showModalDialog, usePrimaryCallback } = useModal()
  const [isLoading, setIsLoading] = useState(false)

 usePrimaryCallback(async () => {
  if (isLoading) return false

  setIsLoading(true)

  try {
    await patchPecContractor(pec.id, statusFieldId, form?.contractor)
    reloadCard()
  } catch (e: any) {
    showModalDialog({
      type: 'error',
      header: 'Ошибка при изменении ПО',
      content: await getErrorMessage(e),
    })
  }

  setIsLoading(false)
}, [isLoading, setIsLoading, form.contractor, pec, statusFieldId, reloadCard])

  return (
    <div style={{minHeight: 375}}>
      <ApiResourceSelect<IPec['contractors'][0], Form>
        apiResource={ApiResource.Organization}
        label='ПО'
        name='contractor'
        initValue={pec.contractors.find(e => e.statusFieldId === statusFieldId)}
        disabled={isLoading}
      />
      {isLoading && <Loader />}
    </div>
  )
}
