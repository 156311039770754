import { FC, ReactNode } from 'react'
import {
  ApiResource, ApiResourceFilter, ApiResourceRecordFormTypes, ApiResourceRecordTypes,
  constructionObjectStatusTypesNames, contractIdentifierNames, ICodeRecord, IHardwareFirmware, INetworkSwitch,
  INetworkSwitchForm, ISelectOption, organizationCalcTypeNames,
} from '@/context/types'
import { ApiCatalogUploadFileRouteKey, apiRoutes, getApiResourceDetailsPath, StringApiRoute } from '@/config/routes'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import CheckboxInput from '@/components/common/form/CheckboxInput'
import DateInput from '@/components/common/form/DateInput'
import NumberInput from '@/components/common/form/NumberInput'
import TextInput from '@/components/common/form/TextInput'
import { formatDate } from '@/utils/formatters'
import ListSelect from '@/components/common/form/ListSelect'
import {
  addressImportExportInfoModal, commonImportExportInfoModal, communicationAddressImportExportInfoModal,
} from '@/components/modals/catalogs/modals'
import { ShowModalDialogParams } from '@/context/ModalContext'
import {
  addressToSelectOption, recordToSelectOptionCustomLabel, recordToSelectOptionNumberLabel,
} from '@/utils/recordToSelectOption'
import { organizationHasVatLabels } from '@/config/boolLabels'


export interface ApiResourceConfig<
  T extends ApiResource,
  TRecord = ApiResourceRecordTypes[T],
  TFormRecord = ApiResourceRecordFormTypes[T]
> {
	/** Название ресурса во множественном числе (название справочника) */
	title: string
	/** Имя записи */
	getRecordTitle?: (record: TRecord) => string
	/** Если true, то создание/редактирование/удаление недоступно */
	immutable?: boolean
	/** Если true, то таблица ресурса отображается на всю ширину страницы с границами */
	fluid?: boolean
	/** Номенклатура полей таблицы ресурса */
	fieldList: IApiResourceField<TRecord>[]
	/** URL для импорта */
	importApiUrl?: StringApiRoute
	/** URL для привязки */
	bindApiUrl?: StringApiRoute
	/** URL для экспорта */
	exportApiUrl?: StringApiRoute
  /** Парараметры модального окна Справки */
  infoModalProps?: ShowModalDialogParams
  /** Путь для прикрепления файлов */
  fileUploadRoute?: typeof apiRoutes[ApiCatalogUploadFileRouteKey]
	/** Номенклатура полей формы */
	formFieldList: IApiResourceFormField<TRecord, TFormRecord>[]
	/** Возвращает подготовленную для отправки на ресурс форму или false в случае если форма не валидна.
		* функция setError(name, message) позволяет отображать сообщения об ошибке для поля name */
  cleanForm?: (
    form: TFormRecord,
    setError?: (name: keyof TFormRecord, message?: string) => void, record?: TRecord,
  ) => TFormRecord | undefined
  catalogListSort?: Sort<TRecord>
  selectSort?: Sort<TRecord> //sort in <ApiResourceSelect />
}

/** Номенклатура поля таблицы ресурса */
interface IApiResourceField<TRecord> {
	/** Имя поля */
	label: string
	/** Значение поля */
	value: (record: TRecord) => ReactNode
	/** Если есть, то возвращает путь ссылки поля */
	path?: (record: TRecord) => string | undefined
  colorValue?: (record: TRecord) => string
}

/** Номенклатура поля формы ресурса */
interface IApiResourceFormField<TRecord, TForm = TRecord> {
	control: FC<any>
	label: string
	name: keyof TForm
	optionList?: ISelectOption[]
	initValue: (record?: TRecord) => any
	required?: ((form: TForm) => boolean)
	helpText?: string
	disabled?: (form: TForm) => boolean
	apiResource?: ApiResource
	filter?: ApiResourceFilter | ((form: TForm) => ApiResourceFilter)
	async?: boolean
	isMulti?: boolean
	recordToOption?: (record: any) => ISelectOption
}

/** Номенклатура таблицы ресурса с одним именем */
const namedRecordFieldList: IApiResourceField<{ name: string }>[] = [{
	label: 'Имя',
	value: record => record.name
}]

/** Номенклатура таблицы ресурса с кодом и именем */
const codeAndNameFieldList: IApiResourceField<ICodeRecord>[] = [
	{ label: 'Код', value: record => record.code },
	{ label: 'Имя', value: record => record.name },
]

/** Номенклатура таблицы прошивки оборудования */
const firmwareFieldList: IApiResourceField<IHardwareFirmware>[] = [
	{ label: 'Имя', value: firmware => firmware.name },
	{ label: 'Рекомендовано', value: firmware => firmware.isRecommended ? 'Да' : ' Нет' },
]

/** Номенклатура формы ресурса с одним именем */
const namedRecordFormFieldList: IApiResourceFormField<{ name: string }>[] = [{
	control: TextInput,
	label: 'Имя',
	name: 'name',
	initValue: record => record?.name,
	required: () => true,
}]

/** Номенклатура формы прошивки оборудования */
const firmwareFormFieldList: IApiResourceFormField<IHardwareFirmware>[] = [
	{
		control: CheckboxInput,
		label: 'Рекомендовано',
		name: 'isRecommended',
		initValue: firmware => firmware?.isRecommended,
	}
]

const hardwareRecordToOption = (hardware: INetworkSwitch) =>
  recordToSelectOptionCustomLabel(hardware, `[${hardware.ipAddress}] ${hardware.code}`)

const recordToOptions = (record: Record<string, string>): ISelectOption[] =>
  Object.keys(record).map(type => ({ label: record[type], value: type }))

const formatBoolean = (val: boolean) => val ? 'Да' : 'Нет'

/** Конфигурация ресурсов API (справочников) */
export const ApiResources: Partial<{ [T in ApiResource]: ApiResourceConfig<T> }> = {
	// Адреса
	[ApiResource.Area]: {
		title: 'Округа',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: [
			{ label: 'Код', value: area => area.code },
			{ label: 'Округ', value: area => area.name },
		],
		exportApiUrl: apiRoutes.exportAreas,
		formFieldList: [
			{
				control: TextInput,
				label: 'Код',
				name: 'code',
				initValue: area => area?.code,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: area => area?.name,
				required: () => true,
			},
		],
	},
	[ApiResource.District]: {
		title: 'Районы',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: [
			{ label: 'Код', value: district => district.code },
			{ label: 'Район', value: district => district.name },
			{ label: 'Округ', value: district => district.area.name },
		],
		exportApiUrl: apiRoutes.exportDistricts,
		formFieldList: [
			{
				control: TextInput,
				label: 'Код',
				name: 'code',
				initValue: district => district?.code,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: district => district?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Area,
				label: 'Округ',
				name: 'areaId',
				initValue: district => district?.area,
				required: () => true,
			},
		],
	},
	[ApiResource.Address]: {
		title: 'Адреса',
    infoModalProps: addressImportExportInfoModal(),
		fieldList: [
			{ label: 'Адрес', value: address => address.name },
			{ label: 'Район', value: address => address.district.name },
			{ label: 'Округ', value: address => address.district.area.name },
			{ label: 'Широта, Долгота', value: address => `${address.gpsLatitude}, ${address.gpsLongitude}` },
			{ label: 'ЕЦХД ID', value: address => address.storageId },
		],
		importApiUrl: apiRoutes.importAddresses,
		exportApiUrl: apiRoutes.exportAddresses,
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: address => address?.name,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'ЕЦХД ID',
				name: 'storageId',
				initValue: address => '' + (address?.storageId ?? ''),
				required: () => true,
			},
			{
				control: TextInput,
				label: 'GPS широта',
				name: 'gpsLatitude',
				initValue: address => address?.gpsLatitude,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'GPS долгота',
				name: 'gpsLongitude',
				initValue: address => address?.gpsLongitude,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.District,
				label: 'Район',
				name: 'districtId',
				initValue: address => address?.district,
				required: () => true,
			},
		],
		selectSort: { field: 'storageId', direction: 'asc' },
	},
	// Контакты
	[ApiResource.OrganizationRole]: {
		title: 'Роли организаций',
    infoModalProps: commonImportExportInfoModal(),
		immutable: true,
		fieldList: codeAndNameFieldList,
		exportApiUrl: apiRoutes.exportOrganizationRoles,
		formFieldList: [],
	},
	[ApiResource.Organization]: {
		title: 'Организации',
    infoModalProps: commonImportExportInfoModal(ApiResource.Organization),
    fileUploadRoute: apiRoutes.uploadOrganizationFile,
		fieldList: [
			{ label: 'Имя', value: organization => organization.name },
			{ label: 'Телефон', value: organization => organization.telephoneNumber },
			{ label: 'Email', value: organization => organization.email },
			{ label: 'Менеджер', value: organization => organization.manager },
			{ label: 'ТП email', value: organization => organization.supportEmail },
			{ label: 'ТП телефон', value: organization => organization.supportTelephoneNumber },
			{ label: 'Адрес', value: organization => organization.addressName },
			{
				label: 'Роль',
				value: organization => organization.roles?.length
          ? organization.roles?.map(role => <div key={role.code}>{role.name} [{role.code}]</div>)
          : '—'
			},
			{
				label: 'Расчет по месяцам или по ОП?',
				value: organization => organizationCalcTypeNames[organization.calcType]
			},
			{
			label: 'С НДС?',
				value: organization => organizationHasVatLabels.get(organization.hasVat)
			},
			{ label: 'ИНН', value: organization => organization.inn },
		],
		importApiUrl: apiRoutes.importOrganizations,
		exportApiUrl: apiRoutes.exportOrganizations,
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: organization => organization?.name,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Телефон',
				name: 'telephoneNumber',
				initValue: organization => organization?.telephoneNumber,
			},
			{
				control: TextInput,
				label: 'Email',
				name: 'email',
				initValue: organization => organization?.email,
			},
      {
        control: TextInput,
        label: 'Менеджер',
        name: 'manager',
        initValue: organization => organization?.manager,
      },
      {
        control: TextInput,
        label: 'ТП email',
        name: 'supportEmail',
        initValue: organization => organization?.supportEmail,
      },
      {
        control: TextInput,
        label: 'ТП телефон',
        name: 'supportTelephoneNumber',
        initValue: organization => organization?.supportTelephoneNumber,
      },
			{
				control: TextInput,
				label: 'Адрес',
				name: 'addressName',
				initValue: organization => organization?.addressName,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.OrganizationRole,
				isMulti: true,
				label: 'Роль',
				name: 'roles',
				initValue: organization => organization?.roles,
			},
			{
				control: ListSelect,
				label: 'Расчет по месяцам или по ОП?',
				name: 'calcType',
				optionList: recordToOptions(organizationCalcTypeNames),
				initValue: organization => organization?.calcType,
			},
			{
				control: ListSelect,
				label: 'С НДС?',
				name: 'hasVat',
				optionList: [
					{ label: organizationHasVatLabels.get(true), value: true },
					{ label: organizationHasVatLabels.get(false), value: false },
				],
				initValue: organization => organization?.hasVat,
			},
			{
				control: TextInput,
				label: 'ИНН',
				name: 'inn',
				initValue: organization => organization?.inn,
			},
		],
	},
	[ApiResource.UserRole]: {
		title: 'Роли пользователей',
    infoModalProps: commonImportExportInfoModal(),
		immutable: true,
		fieldList: codeAndNameFieldList,
		exportApiUrl: apiRoutes.exportUserRoles,
		formFieldList: [],
	},
	[ApiResource.User]: {
		title: 'Пользователи',
    infoModalProps: commonImportExportInfoModal(ApiResource.User),
		getRecordTitle: user => user.login,
		fieldList: [
			{ label: 'Логин', value: user => user.login },
			{ label: 'ФИО', value: user => user.name },
			{ label: 'Имя', value: user => user.firstName },
			{ label: 'Фамилия', value: user => user.lastName },
			{
				label: 'Организация',
				value: user => user.organization.name,
				path: user => getApiResourceDetailsPath(ApiResource.Organization, user.organization.id)
			},
			{ label: 'Телефон', value: user => user.telephoneNumber },
			{ label: 'Email', value: user => user.email },
			{
				label: 'Роль',
				value: user => user.userRoles.length > 0
					? user.userRoles.map(role => <div key={role.code}>{role.name} [{role.code}]</div>)
					: '—',
			},
			{ label: 'Статус', value: user => user.isActive ? 'Активный' : 'Неактивный' },
			{ label: 'КЛ', value: user => user.isContactPerson ? 'Да' : '—' },
			{ label: 'Инциденты Kns', value: user => user.isIncidentKns ? 'Да' : '—' },
			{ label: 'Описание', value: user => user.description },
		],
		importApiUrl: apiRoutes.importUsers,
		exportApiUrl: apiRoutes.exportUsers,
		formFieldList: [
			{
				control: TextInput,
				label: 'Логин',
				name: 'login',
				initValue: user => user?.login,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Пароль',
				name: 'password',
				initValue: () => undefined
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.UserRole,
				isMulti: true,
				label: 'Роль',
				name: 'userRoles',
				initValue: user => user?.userRoles,
				required: () => true,
			},
			{
				control: CheckboxInput,
				label: 'Пользователь активный',
				name: 'isActive',
				initValue: user => user?.isActive,
			},
			{
				control: TextInput,
				label: 'ФИО',
				name: 'name',
				initValue: user => user?.name,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Имя',
				name: 'firstName',
				initValue: user => user?.firstName,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Фамилия',
				name: 'lastName',
				initValue: user => user?.lastName,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Телефон',
				name: 'telephoneNumber',
				initValue: user => user?.telephoneNumber,
			},
			{
				control: TextInput,
				label: 'Email',
				name: 'email',
				initValue: user => user?.email,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Organization,
				label: 'Организация',
				name: 'organization',
				initValue: user => user?.organization,
				required: () => true,
			},
			{
				control: CheckboxInput,
				label: 'Контактное лицо',
				name: 'isContactPerson',
				initValue: user => user?.isContactPerson,
			},
			{
				control: CheckboxInput,
				label: 'KNS',
				name: 'isIncidentKns',
				initValue: user => user?.isIncidentKns,
			},
			{
				control: TextInput,
				label: 'Должность и доп. информация',
				name: 'description',
				initValue: user => user?.description,
			},
		],
		catalogListSort: { field: 'active', direction: 'desc' },
		cleanForm: form => {
			const cleanedForm = { ...form }
			if (!form.password)
				delete cleanedForm.password
			return cleanedForm
		}
	},
	// Оборудование
	[ApiResource.Vrf]: {
		title: 'VRF',
    infoModalProps: commonImportExportInfoModal(),
		exportApiUrl: apiRoutes.exportVrfs,
		fieldList: namedRecordFieldList,
		formFieldList: namedRecordFormFieldList,
	},
	[ApiResource.IncidentStage]: {
		title: 'Этапы инцидентов',
    infoModalProps: commonImportExportInfoModal(),
		immutable: true,
		fieldList: [
			{ label: 'Код', value: stage => stage.code },
			{ label: 'Имя', value: stage => stage.name },
		],
		exportApiUrl: apiRoutes.exportIncidentStages,
		formFieldList: [],
	},
	[ApiResource.IncidentStatus]: {
		title: 'Финальные статусы инцидентов',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: namedRecordFieldList,
		exportApiUrl: apiRoutes.exportIncidentStatuses,
		formFieldList: namedRecordFormFieldList,
	},
	// Коммутаторы
	[ApiResource.NetworkSwitchModel]: {
    infoModalProps: commonImportExportInfoModal(),
		title: 'Модели коммутаторов',
		exportApiUrl: apiRoutes.exportNetworkSwitchModels,
		fieldList: namedRecordFieldList,
		formFieldList: namedRecordFormFieldList,
	},
	[ApiResource.NetworkSwitchFirmware]: {
		title: 'Прошивки коммутаторов',
    infoModalProps: commonImportExportInfoModal(),
		immutable: true,
		exportApiUrl: apiRoutes.exportNetworkSwitchFirmwares,
		fieldList: firmwareFieldList,
		formFieldList: firmwareFormFieldList,
	},
	[ApiResource.NetworkSwitchPreset]: {
		title: 'Пресеты коммутаторов',
    infoModalProps: commonImportExportInfoModal(),
    fileUploadRoute: apiRoutes.uploadNetworkSwitchPresetFile,
		exportApiUrl: apiRoutes.exportNetworkSwitchPresets,
		fieldList: [
			{ label: 'Имя', value: preset => preset.name },
			{
				label: 'Модель',
				value: preset => preset.model.name,
				path: preset => getApiResourceDetailsPath(ApiResource.NetworkSwitchModel, preset.model.id)
			},
			{ label: 'Логин', value: preset => preset.login },
			{ label: 'Пароль', value: preset => preset.password },
		],
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: preset => preset?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.NetworkSwitchModel,
				label: 'Модель',
				name: 'model',
				initValue: preset => preset?.model,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Логин',
				name: 'login',
				initValue: preset => preset?.login,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Пароль',
				name: 'password',
				initValue: preset => preset?.password,
			},
		],
	},
	[ApiResource.NetworkSwitch]: {
		title: 'Коммутаторы',
    infoModalProps: commonImportExportInfoModal(ApiResource.NetworkSwitch),
		getRecordTitle: networkSwitch => `${networkSwitch.code} [${networkSwitch.ipAddress}]`,
		fluid: true,
		fieldList: [
			{ label: 'ID', value: networkSwitch => networkSwitch.id },
			{ label: 'Имя', value: networkSwitch => networkSwitch.code },
			{ label: 'ID адреса ЕЦХД', value: networkSwitch => networkSwitch.address.storageId },
			{ label: 'Округ', value: networkSwitch => networkSwitch.address.district.area.name },
			{ label: 'Район', value: networkSwitch => networkSwitch.address.district.name },
			{ label: 'Адрес', value: networkSwitch => networkSwitch.address.name },
			{ label: 'Описание месторасположения', value: networkSwitch => networkSwitch.locationDescription },
			{ label: 'VRF', value: networkSwitch => networkSwitch.vrfs?.map(vrf => <div key={vrf.id}>{vrf.name}</div>) },
			{ label: 'IP-адрес', value: networkSwitch => networkSwitch.ipAddress },
			{ label: 'Подсеть', value: networkSwitch => networkSwitch.subnetwork },
			{
				label: 'IP-адрес родительского коммутатора',
				value: networkSwitch => networkSwitch.networkSwitchPort?.networkSwitch.ipAddress,
				path: networkSwitch => networkSwitch.networkSwitchPort ? getApiResourceDetailsPath(ApiResource.NetworkSwitch, networkSwitch.networkSwitchPort.networkSwitch.id) : undefined
			},
			{ label: 'Порт родительского  коммутатора', value: networkSwitch => networkSwitch.networkSwitchPort?.portNumber },
			{
				label: 'IP-адресы и порты (исходящие)',
				value: networkSwitch => networkSwitch.ports?.map(port =>
					<div key={port.id}>
						{port.hardware.ipAddress}:{port.portNumber}
					</div>
        )
			},
			{ label: 'Статус', value: networkSwitch => networkSwitch.status.name },
			{
				label: 'Пресет',
				value: networkSwitch => networkSwitch.preset.name,
				path: networkSwitch => getApiResourceDetailsPath(ApiResource.NetworkSwitchPreset, networkSwitch.preset.id)
			},
			{ label: 'Логин', value: networkSwitch => networkSwitch.preset.login },
			{ label: 'Пароль', value: networkSwitch => networkSwitch.preset.password },
			{
				label: 'Прошивка',
				value: networkSwitch =>
					<>
						{networkSwitch.firmware?.name}
						{networkSwitch.firmware?.isRecommended == null
							? ''
							: ' ' + (networkSwitch.firmware?.isRecommended ? '+' : '–')}
					</>
			},
			{
        label: 'Состояние',
        value: networkSwitch => networkSwitch.state.name,
        colorValue: networkSwitch => networkSwitch.state.code,
      },
			{ label: 'Серийный номер', value: networkSwitch => networkSwitch.serialNumber },
			{ label: 'Описание', value: networkSwitch => networkSwitch.description },
			{ label: 'Оборудование АО «Ситроникс»', value: networkSwitch => formatBoolean(networkSwitch.isSitronicsHardware) },
		],
		importApiUrl: apiRoutes.importNetworkSwitches,
		bindApiUrl: apiRoutes.importHardwareBind,
		exportApiUrl: apiRoutes.exportNetworkSwitches,
		formFieldList: [
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.HardwareStatus,
				label: 'Статус',
				name: 'status',
				initValue: networkSwitch => networkSwitch?.status,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Address,
				async: true,
        recordToOption: addressToSelectOption,
				label: 'Адрес',
				name: 'address',
				initValue: networkSwitch => networkSwitch?.address,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Описание местоположения',
				name: 'locationDescription',
				initValue: networkSwitch => networkSwitch?.locationDescription,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Vrf,
				isMulti: true,
				label: 'VRF',
				name: 'vrfs',
				initValue: networkSwitch => networkSwitch?.vrfs,
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'IP-адрес',
				name: 'ipAddress',
				initValue: networkSwitch => networkSwitch?.ipAddress,
				helpText: '255.255.255.255',
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'Подсеть',
				name: 'subnetwork',
				initValue: networkSwitch => networkSwitch?.subnetwork,
				helpText: '255.255.255.255/32',
			},
			{
				control: CheckboxInput,
				label: 'Коммутатор подключен через соседний коммутатор',
				name: 'hasParentNetworkSwitch',
				initValue: networkSwitch => !!networkSwitch?.networkSwitchPort,
				helpText: 'Позволяет выбрать родительский коммутатор',
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.NetworkSwitch,
				filter: form => ({ 'address.id': form.address, 'vrfs.id': form.vrfs }),
				async: true,
				recordToOption: hardwareRecordToOption,
				label: 'Родительский коммутатор',
				name: 'networkSwitch',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.networkSwitch,
				required: () => true,
				helpText: 'Родительский коммутатор должен находиться на том же адресе и в том же VRF',
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: TextInput,
				label: 'Порт на родительском коммутаторе',
				name: 'networkSwitchPortNumber',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.portNumber,
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.NetworkSwitchPreset,
				label: 'Пресет',
				name: 'preset',
				initValue: networkSwitch => networkSwitch?.preset,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Серийный номер',
				name: 'serialNumber',
				initValue: networkSwitch => networkSwitch?.serialNumber,
			},
			{
				control: TextInput,
				label: 'Описание',
				name: 'description',
				initValue: networkSwitch => networkSwitch?.description,
			},
			{
				control: CheckboxInput,
				label: 'Оборудование АО «Ситроникс»',
				name: 'isSitronicsHardware',
				initValue: networkSwitch => networkSwitch?.isSitronicsHardware,
			},
		],
		cleanForm: (form, setError, networkSwitch) => {
			if (networkSwitch && form.networkSwitch === networkSwitch.id) {
				setError?.('networkSwitch', 'Нельзя подключить коммутатор к самому себе. Выберите другой коммутатор.')
				return
			}
			const cleanedForm: INetworkSwitchForm = {
				...form,
				networkSwitch: form.hasParentNetworkSwitch ? form.networkSwitch : null,
				networkSwitchPortNumber: form.hasParentNetworkSwitch ? form.networkSwitchPortNumber : null,
				hasParentNetworkSwitch: undefined,
			}
			return cleanedForm
		},
	},
	//Маршрутизаторы
	[ApiResource.RouterModel]: {
		title: 'Модели маршрутизаторов',
    infoModalProps: commonImportExportInfoModal(),
		exportApiUrl: apiRoutes.exportRouterModels,
		fieldList: namedRecordFieldList,
		formFieldList: namedRecordFormFieldList,
	},
	[ApiResource.Router]: {
		title: 'Маршрутизаторы',
    infoModalProps: commonImportExportInfoModal(ApiResource.Router),
		getRecordTitle: router => `${router.code} [${router.ipAddress}]`,
		fluid: true,
		fieldList: [
			{ label: 'ID', value: router => router.id },
			{ label: 'Имя', value: router => router.code },
			{ label: 'ID адреса ЕЦХД', value: router => router.address.storageId },
			{ label: 'Округ', value: router => router.address.district.area.name },
			{ label: 'Район', value: router => router.address.district.name },
			{ label: 'Адрес', value: router => router.address.name },
			{ label: 'Описание месторасположения', value: router => router.locationDescription },
			{ label: 'VRF', value: router => router.vrf?.name },
			{ label: 'IP-адрес', value: router => router.ipAddress },
			{ label: 'Подсеть', value: router => router.subnetwork },
			{
				label: 'IP-адрес коммутатора',
				value: router => router.networkSwitchPort?.networkSwitch.ipAddress,
				path: router => router.networkSwitchPort ? getApiResourceDetailsPath(ApiResource.NetworkSwitch, router.networkSwitchPort.networkSwitch.id) : undefined
			},
			{ label: 'Порт роутера на коммутаторе', value: router => router.networkSwitchPort?.portNumber },
			{ label: 'Статус', value: router => router.status.name },
			{
				label: 'Пресет',
				value: router => router.preset.name,
				path: router => getApiResourceDetailsPath(ApiResource.RouterPreset, router.preset.id)
			},
			{ label: 'Логин', value: router => router.preset.login },
			{ label: 'Пароль', value: router => router.preset.password },
			{
				label: 'Прошивка',
				value: router =>
					<>
						{router.firmware?.name}
						{router.firmware?.isRecommended == null
							? ''
							: ' ' + (router.firmware?.isRecommended ? '+' : '–')}
					</>
			},
			{
        label: 'Состояние',
        value: router => router.state.name,
        colorValue: router => router.state.code,
      },
			{ label: 'Серийный номер', value: router => router.serialNumber },
			{ label: 'Описание', value: router => router.description },
			{ label: 'Оборудование АО «Ситроникс»', value: router => formatBoolean(router.isSitronicsHardware) },
		],
		importApiUrl: apiRoutes.importRouters,
		bindApiUrl: apiRoutes.importHardwareBind,
		exportApiUrl: apiRoutes.exportRouter,
		formFieldList: [
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.HardwareStatus,
				label: 'Статус',
				name: 'status',
				initValue: router => router?.status,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Address,
				async: true,
        recordToOption: addressToSelectOption,
				label: 'Адрес',
				name: 'address',
				initValue: router => router?.address,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Описание местоположения',
				name: 'locationDescription',
				initValue: router => router?.locationDescription,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Vrf,
				label: 'VRF',
				name: 'vrf',
				initValue: router => router?.vrf,
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'IP-адрес',
				name: 'ipAddress',
				initValue: router => router?.ipAddress,
				helpText: '255.255.255.255',
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'Подсеть',
				name: 'subnetwork',
				initValue: router => router?.subnetwork,
				helpText: '255.255.255.255/32',
			},
			{
				control: CheckboxInput,
				label: 'Маршрутизатор подключен через коммутатор',
				name: 'hasParentNetworkSwitch',
				initValue: router => !!router?.networkSwitchPort,
				helpText: 'Позволяет выбрать коммутатор',
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.NetworkSwitch,
				filter: form => ({ 'address.id': form.address, 'vrfs.id': form.vrf }),
				async: true,
				recordToOption: hardwareRecordToOption,
				label: 'Коммутатор',
				name: 'networkSwitch',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.networkSwitch,
				required: () => true,
				helpText: 'Коммутатор должен находиться на том же адресе и в том же VRF',
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: TextInput,
				label: 'Порт на коммутаторе',
				name: 'networkSwitchPortNumber',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.portNumber,
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.RouterPreset,
				label: 'Пресет',
				name: 'preset',
				initValue: router => router?.preset,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Серийный номер',
				name: 'serialNumber',
				initValue: router => router?.serialNumber,
			},
			{
				control: TextInput,
				label: 'Описание',
				name: 'description',
				initValue: router => router?.description,
			},
			{
				control: CheckboxInput,
				label: 'Оборудование АО «Ситроникс»',
				name: 'isSitronicsHardware',
				initValue: router => router?.isSitronicsHardware,
			},
		],
		cleanForm: form => {
			const cleanedForm: typeof form = {
				...form,
				networkSwitch: form.hasParentNetworkSwitch ? form.networkSwitch : null,
				networkSwitchPortNumber: form.hasParentNetworkSwitch ? form.networkSwitchPortNumber : null,
				hasParentNetworkSwitch: undefined,
			}
			return cleanedForm
		},
	},
	[ApiResource.RouterFirmware]: {
		title: 'Прошивки маршрутизаторов',
    infoModalProps: commonImportExportInfoModal(),
		immutable: true,
		exportApiUrl: apiRoutes.exportRouterFirmwares,
		fieldList: firmwareFieldList,
		formFieldList: firmwareFormFieldList,
	},
	[ApiResource.RouterPreset]: {
		title: 'Пресеты маршрутизаторов',
    infoModalProps: commonImportExportInfoModal(),
    fileUploadRoute: apiRoutes.uploadRouterPresetFile,
		exportApiUrl: apiRoutes.exportRoutersPresets,
		fieldList: [
			{ label: 'Имя', value: preset => preset.name },
			{
				label: 'Модель',
				value: preset => preset.model.name,
				path: preset => getApiResourceDetailsPath(ApiResource.RouterModel, preset.model.id)
			},
			{ label: 'Логин', value: preset => preset.login },
			{ label: 'Пароль', value: preset => preset.password },
		],
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: preset => preset?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.RouterModel,
				label: 'Модель',
				name: 'model',
				initValue: preset => preset?.model,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Логин',
				name: 'login',
				initValue: preset => preset?.login,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Пароль',
				name: 'password',
				initValue: preset => preset?.password,
			},
		],
	},
	// IP-камеры
	[ApiResource.IpCameraModel]: {
		title: 'Модели IP-камер',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: namedRecordFieldList,
		exportApiUrl: apiRoutes.exportIpCameraModels,
		formFieldList: namedRecordFormFieldList,
	},
	[ApiResource.IpCameraFirmware]: {
		title: 'Прошивки IP-камер',
    infoModalProps: commonImportExportInfoModal(),
		immutable: true,
		fieldList: firmwareFieldList,
		exportApiUrl: apiRoutes.exportIpCameraFirmwares,
		formFieldList: firmwareFormFieldList,
	},
	[ApiResource.IpCameraPreset]: {
		title: 'Пресеты IP-камер',
    infoModalProps: commonImportExportInfoModal(),
    fileUploadRoute: apiRoutes.uploadIpCameraPresetFile,
		fieldList: [
			{ label: 'Имя', value: preset => preset.name },
			{
				label: 'Модель',
				value: preset => preset.model.name,
				path: preset => getApiResourceDetailsPath(ApiResource.IpCameraModel, preset.model.id)
			},
			{ label: 'Логин', value: preset => preset.login },
			{ label: 'Пароль', value: preset => preset.password },
		],
		exportApiUrl: apiRoutes.exportIpCameraPresets,
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: preset => preset?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.IpCameraModel,
				label: 'Модель',
				name: 'model',
				initValue: preset => preset?.model,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Логин',
				name: 'login',
				initValue: preset => preset?.login,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Пароль',
				name: 'password',
				initValue: preset => preset?.password,
			},
		],
	},
	[ApiResource.IpCamera]: {
		title: 'IP-камеры',
    infoModalProps: commonImportExportInfoModal(ApiResource.IpCamera),
		getRecordTitle: ipCamera => `${ipCamera.code} [${ipCamera.ipAddress}]`,
		fluid: true,
		fieldList: [
			{ label: 'ID', value: ipCamera => ipCamera.id },
			{ label: 'Имя', value: ipCamera => ipCamera.code },
			{ label: 'Видеомаркер', value: ipCamera => ipCamera.videoMarkerCode },
			{ label: 'ID адреса ЕЦХД', value: ipCamera => ipCamera.address.storageId },
			{ label: 'Округ', value: ipCamera => ipCamera.address.district.area.name },
			{ label: 'Район', value: ipCamera => ipCamera.address.district.name },
			{ label: 'Адрес', value: ipCamera => ipCamera.address.name },
			{ label: 'Описание месторасположения', value: ipCamera => ipCamera.locationDescription },
			{ label: 'VRF', value: ipCamera => ipCamera.vrf?.name },
			{ label: 'IP-адрес', value: ipCamera => ipCamera.ipAddress },
			{ label: 'Подсеть', value: ipCamera => ipCamera.subnetwork },
			{
				label: 'IP-адрес коммутатора',
				value: ipCamera => ipCamera.networkSwitchPort?.networkSwitch.ipAddress,
				path: ipCamera => ipCamera.networkSwitchPort ? getApiResourceDetailsPath(ApiResource.NetworkSwitch, ipCamera.networkSwitchPort.networkSwitch.id) : undefined
			},
			{ label: 'Порт камеры на коммутаторе', value: ipCamera => ipCamera.networkSwitchPort?.portNumber },
			{ label: 'Статус', value: ipCamera => ipCamera.status.name },
			{
				label: 'Пресет',
				value: ipCamera => ipCamera.preset.name,
				path: ipCamera => getApiResourceDetailsPath(ApiResource.IpCameraPreset, ipCamera.preset.id)
			},
			{ label: 'Логин', value: ipCamera => ipCamera.preset.login },
			{ label: 'Пароль', value: ipCamera => ipCamera.preset.password },
			{
				label: 'Прошивка', value: ipCamera =>
					<>
						{ipCamera.firmware?.name}
						{ipCamera.firmware?.isRecommended == null
							? ''
							: ' ' + (ipCamera.firmware?.isRecommended ? '+' : '–')}
					</>
			},
			{
        label: 'Состояние',
        value: ipCamera => ipCamera.state.name,
        colorValue: ipCamera => ipCamera.state.code,
      },
			{ label: 'Звук включён?', value: ipCamera => ipCamera.hasSoundEnabled == null ? '?' : formatBoolean(ipCamera.hasSoundEnabled)},
			{ label: 'Эталонный снимок', value: ipCamera => formatBoolean(!!ipCamera.referenceSnapshot)},
			{ label: 'Серийный номер', value: ipCamera => ipCamera.serialNumber },
			{ label: 'Описание', value: ipCamera => ipCamera.description },
			{ label: 'Оборудование АО «Ситроникс»', value: ipCamera => formatBoolean(ipCamera.isSitronicsHardware)},
		],
		importApiUrl: apiRoutes.importIpCameras,
		bindApiUrl: apiRoutes.importHardwareBind,
		exportApiUrl: apiRoutes.exportIpCameras,
		formFieldList: [
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.HardwareStatus,
				label: 'Статус',
				name: 'status',
				initValue: ipCamera => ipCamera?.status,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Address,
				async: true,
        recordToOption: addressToSelectOption,
				label: 'Адрес',
				name: 'address',
				initValue: ipCamera => ipCamera?.address,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Описание местоположения',
				name: 'locationDescription',
				initValue: ipCamera => ipCamera?.locationDescription,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Vrf,
				label: 'VRF',
				name: 'vrf',
				initValue: ipCamera => ipCamera?.vrf,
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'IP-адрес',
				name: 'ipAddress',
				initValue: ipCamera => ipCamera?.ipAddress,
				helpText: '255.255.255.255',
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'Подсеть',
				name: 'subnetwork',
				initValue: ipCamera => ipCamera?.subnetwork,
				helpText: '255.255.255.255/32',
			},
			{
				control: CheckboxInput,
				label: 'IP-Камера подключена через коммутатор',
				name: 'hasParentNetworkSwitch',
				initValue: ipCamera => !!ipCamera?.networkSwitchPort,
				helpText: 'Позволяет выбрать коммутатор',
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.NetworkSwitch,
				filter: form => ({ 'address.id': form.address, 'vrfs.id': form.vrf }),
				async: true,
				recordToOption: hardwareRecordToOption,
				label: 'Коммутатор',
				name: 'networkSwitch',
				initValue: ipCamera => ipCamera?.networkSwitchPort?.networkSwitch,
				required: () => true,
				helpText: 'Коммутатор должен находиться на том же адресе и в том же VRF',
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: TextInput,
				label: 'Порт на коммутаторе',
				name: 'networkSwitchPortNumber',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.portNumber,
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.IpCameraPreset,
				label: 'Пресет',
				name: 'preset',
				initValue: ipCamera => ipCamera?.preset,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Серийный номер',
				name: 'serialNumber',
				initValue: ipCamera => ipCamera?.serialNumber,
			},
			{
				control: TextInput,
				label: 'Описание',
				name: 'description',
				initValue: ipCamera => ipCamera?.description,
			},
			{
				control: CheckboxInput,
				label: 'Оборудование АО «Ситроникс»',
				name: 'isSitronicsHardware',
				initValue: ipCamera => ipCamera?.isSitronicsHardware,
			},
		],
		cleanForm: form => {
			const cleanedForm: typeof form = {
				...form,
				networkSwitch: form.hasParentNetworkSwitch ? form.networkSwitch : null,
				networkSwitchPortNumber: form.hasParentNetworkSwitch ? form.networkSwitchPortNumber : null,
				hasParentNetworkSwitch: undefined,
			}
			return cleanedForm
		},
	},
	// Видеосерверы
	[ApiResource.VideoServerModel]: {
		title: 'Модели видеосерверов',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: namedRecordFieldList,
		formFieldList: namedRecordFormFieldList,
	},
	[ApiResource.VideoServerFirmware]: {
		title: 'Прошивки видеосерверов',
    infoModalProps: commonImportExportInfoModal(),
		immutable: true,
		fieldList: firmwareFieldList,
		formFieldList: firmwareFormFieldList,
	},
	[ApiResource.VideoServerPreset]: {
		title: 'Пресеты видеосерверов',
    infoModalProps: commonImportExportInfoModal(),
    fileUploadRoute: apiRoutes.uploadVideoServerPresetFile,
		fieldList: [
			{ label: 'Имя', value: preset => preset.name },
			{
				label: 'Модель',
				value: preset => preset.model.name,
				path: preset => getApiResourceDetailsPath(ApiResource.VideoServerModel, preset.model.id)
			},
			{ label: 'Логин', value: preset => preset.login },
			{ label: 'Пароль', value: preset => preset.password },
		],
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: preset => preset?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.VideoServerModel,
				label: 'Модель',
				name: 'model',
				initValue: preset => preset?.model,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Логин',
				name: 'login',
				initValue: preset => preset?.login,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Пароль',
				name: 'password',
				initValue: preset => preset?.password,
			},
		],
	},
	[ApiResource.VideoServer]: {
		title: 'Видеосерверы',
    infoModalProps: commonImportExportInfoModal(ApiResource.VideoServer),
		getRecordTitle: videoServer => `${videoServer.code} [${videoServer.ipAddress}]`,
		fluid: true,
		fieldList: [
			{ label: 'ID', value: videoServer => videoServer.id },
			{ label: 'Имя', value: videoServer => videoServer.code },
			{ label: 'ID адреса ЕЦХД', value: videoServer => videoServer.address.storageId },
			{ label: 'Округ', value: videoServer => videoServer.address.district.area.name },
			{ label: 'Район', value: videoServer => videoServer.address.district.name },
			{ label: 'Адрес', value: videoServer => videoServer.address.name },
			{ label: 'Описание месторасположения', value: videoServer => videoServer.locationDescription },
			{ label: 'VRF', value: videoServer => videoServer.vrf?.name },
			{ label: 'IP-адрес', value: videoServer => videoServer.ipAddress },
			{ label: 'Подсеть', value: videoServer => videoServer.subnetwork },
			{
				label: 'IP-адрес коммутатора',
				value: videoServer => videoServer.networkSwitchPort?.networkSwitch.ipAddress,
				path: videoServer => videoServer.networkSwitchPort ? getApiResourceDetailsPath(ApiResource.NetworkSwitch, videoServer.networkSwitchPort.networkSwitch.id) : undefined
			},
			{ label: 'Порт видеосервера на коммутаторе', value: videoServer => videoServer.networkSwitchPort?.portNumber },
			{ label: 'Статус', value: videoServer => videoServer.status.name },
			{
				label: 'Пресет',
				value: videoServer => videoServer.preset.name,
				path: videoServer => getApiResourceDetailsPath(ApiResource.VideoServerPreset, videoServer.preset.id)
			},
			{ label: 'Логин', value: videoServer => videoServer.preset.login },
			{ label: 'Пароль', value: videoServer => videoServer.preset.password },
			{
				label: 'Прошивка',
				value: videoServer =>
					<>
						{videoServer.firmware?.name}
						{videoServer.firmware?.isRecommended == null
							? ''
							: ' ' + (videoServer.firmware?.isRecommended ? '+' : '–')}
					</>
			},
			{
        label: 'Состояние',
        value: videoServer => videoServer.state.name,
        colorValue: videoServer => videoServer.state.code,
      },
			{ label: 'Серийный номер', value: videoServer => videoServer.serialNumber },
			{ label: 'Описание', value: videoServer => videoServer.description },
			{ label: 'Оборудование АО «Ситроникс»', value: videoServer => formatBoolean(videoServer.isSitronicsHardware)},
		],
		importApiUrl: apiRoutes.importVideoServers,
		bindApiUrl: apiRoutes.importHardwareBind,
		exportApiUrl: apiRoutes.exportVideoServers,
		formFieldList: [
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.HardwareStatus,
				label: 'Статус',
				name: 'status',
				initValue: videoServer => videoServer?.status,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Address,
				async: true,
        recordToOption: addressToSelectOption,
				label: 'Адрес',
				name: 'address',
				initValue: videoServer => videoServer?.address,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Описание местоположения',
				name: 'locationDescription',
				initValue: videoServer => videoServer?.locationDescription,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Vrf,
				label: 'VRF',
				name: 'vrf',
				initValue: videoServer => videoServer?.vrf,
        required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'IP-адрес',
				name: 'ipAddress',
				initValue: videoServer => videoServer?.ipAddress,
				helpText: '255.255.255.255',
        required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'Подсеть',
				name: 'subnetwork',
				initValue: videoServer => videoServer?.subnetwork,
				helpText: '255.255.255.255/32',
			},
			{
				control: CheckboxInput,
				label: 'Видеосервер подключен через коммутатор',
				name: 'hasParentNetworkSwitch',
				initValue: videoServer => !!videoServer?.networkSwitchPort,
				helpText: 'Позволяет выбрать коммутатор',
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.NetworkSwitch,
				filter: form => ({ 'address.id': form.address, 'vrfs.id': form.vrf }),
				async: true,
				recordToOption: hardwareRecordToOption,
				label: 'Коммутатор',
				name: 'networkSwitch',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.networkSwitch,
				required: () => true,
				helpText: 'Коммутатор должен находиться на том же адресе и в том же VRF',
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: TextInput,
				label: 'Порт на коммутаторе',
				name: 'networkSwitchPortNumber',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.portNumber,
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.VideoServerPreset,
				label: 'Пресет',
				name: 'preset',
				initValue: videoServer => videoServer?.preset,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Серийный номер',
				name: 'serialNumber',
				initValue: videoServer => videoServer?.serialNumber,
			},
			{
				control: TextInput,
				label: 'Описание',
				name: 'description',
				initValue: videoServer => videoServer?.description,
			},
			{
				control: CheckboxInput,
				label: 'Оборудование АО «Ситроникс»',
				name: 'isSitronicsHardware',
				initValue: videoServer => videoServer?.isSitronicsHardware,
			},
		],
		cleanForm: form => {
			const cleanedForm: typeof form = {
				...form,
				networkSwitch: form.hasParentNetworkSwitch ? form.networkSwitch : null,
				networkSwitchPortNumber: form.hasParentNetworkSwitch ? form.networkSwitchPortNumber : null,
				hasParentNetworkSwitch: undefined,
			}
			return cleanedForm
		},
	},
	// Точки доступа
	[ApiResource.AccessPointModel]: {
		title: 'Модели точек доступа',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: namedRecordFieldList,
		exportApiUrl: apiRoutes.exportAccessPointModels,
		formFieldList: namedRecordFormFieldList,
	},
	[ApiResource.AccessPointFirmware]: {
		title: 'Прошивки точек доступа',
    infoModalProps: commonImportExportInfoModal(),
		immutable: true,
		fieldList: firmwareFieldList,
		exportApiUrl: apiRoutes.exportAccessPointFirmwares,
		formFieldList: firmwareFormFieldList,
	},
	[ApiResource.AccessPoint]: {
		title: 'Точки доступа',
    infoModalProps: commonImportExportInfoModal(ApiResource.AccessPoint),
		getRecordTitle: accessPoint => `${accessPoint.code} [${accessPoint.macAddress}]`,
		fluid: true,
		immutable: false,
		fieldList: [
			{ label: 'ID', value: accessPoint => accessPoint.id },
			{ label: 'Имя', value: accessPoint => accessPoint.code },
			{ label: 'ID адреса ЕЦХД', value: accessPoint => accessPoint.address.storageId },
			{ label: 'Округ', value: accessPoint => accessPoint.address.district.area.name },
			{ label: 'Район', value: accessPoint => accessPoint.address.district.name },
			{ label: 'Адрес', value: accessPoint => accessPoint.address.name },
			{ label: 'Описание месторасположения', value: accessPoint => accessPoint.locationDescription },
			{ label: 'MAC-адрес', value: accessPoint => accessPoint.macAddress },
			{ label: 'VRF', value: accessPoint => accessPoint.vrf?.name },
			{ label: 'IP-адрес', value: accessPoint => accessPoint.ipAddress },
			{ label: 'Подсеть', value: accessPoint => accessPoint.subnetwork },
			{
				label: 'IP-адрес коммутатора',
				value: accessPoint => accessPoint.networkSwitchPort?.networkSwitch.ipAddress,
				path: accessPoint => accessPoint.networkSwitchPort ? getApiResourceDetailsPath(ApiResource.NetworkSwitch, accessPoint.networkSwitchPort.networkSwitch.id) : undefined
			},
			{ label: 'Порт точки доступа на коммутаторе', value: accessPoint => accessPoint.networkSwitchPort?.portNumber },
			{ label: 'Статус', value: accessPoint => accessPoint.status.name },
			{ label: 'Модель', value: accessPoint => accessPoint.model.name },
			{
				label: 'Прошивка',
				value: accessPoint =>
					<>
						{accessPoint.firmware?.name}
						{accessPoint.firmware?.isRecommended == null
							? ''
							: ' ' + (accessPoint.firmware?.isRecommended ? '+' : '–')}
					</>
			},
			{
        label: 'Состояние',
        value: accessPoint => accessPoint.state.name,
        colorValue: accessPoint => accessPoint.state.code,
      },
			{ label: 'Серийный номер', value: accessPoint => accessPoint.serialNumber },
			{ label: 'Описание', value: accessPoint => accessPoint.description },
			{ label: 'Оборудование АО «Ситроникс»', value: accessPoint => formatBoolean(accessPoint.isSitronicsHardware)},
		],
		importApiUrl: apiRoutes.importAccessPoints,
		bindApiUrl: apiRoutes.importHardwareBind,
		exportApiUrl: apiRoutes.exportAccessPoints,
		formFieldList: [
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.HardwareStatus,
				label: 'Статус',
				name: 'status',
				initValue: accessPoint => accessPoint?.status,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Address,
				async: true,
        recordToOption: addressToSelectOption,
				label: 'Адрес',
				name: 'address',
				initValue: accessPoint => accessPoint?.address,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Описание местоположения',
				name: 'locationDescription',
				initValue: accessPoint => accessPoint?.locationDescription,
			},
			{
				control: TextInput,
				label: 'MAC-адрес',
				name: 'macAddress',
				initValue: accessPoint => accessPoint?.macAddress,
				helpText: 'FF:FF:FF:FF:FF:FF',
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Vrf,
				label: 'VRF',
				name: 'vrf',
				initValue: accessPoint => accessPoint?.vrf,
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'IP-адрес',
				name: 'ipAddress',
				initValue: accessPoint => accessPoint?.ipAddress,
				helpText: '255.255.255.255',
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'Подсеть',
				name: 'subnetwork',
				initValue: accessPoint => accessPoint?.subnetwork,
				helpText: '255.255.255.255/32',
			},
			{
				control: CheckboxInput,
				label: 'Точка доступа подключена через коммутатор',
				name: 'hasParentNetworkSwitch',
				initValue: accessPoint => !!accessPoint?.networkSwitchPort,
				helpText: 'Позволяет выбрать коммутатор',
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.NetworkSwitch,
				// filter: form => ({ 'address.id': form.address, 'vrfs.id': form.vrf }),
				async: true,
				recordToOption: hardwareRecordToOption,
				label: 'Коммутатор',
				name: 'networkSwitch',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.networkSwitch,
				required: () => true,
				// helpText: 'Коммутатор должен находиться на том же адресе и в том же VRF',
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: TextInput,
				label: 'Порт на коммутаторе',
				name: 'networkSwitchPortNumber',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.portNumber,
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.AccessPointModel,
				label: 'Модель',
				name: 'model',
				initValue: preset => preset?.model,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Серийный номер',
				name: 'serialNumber',
				initValue: accessPoint => accessPoint?.serialNumber,
			},
			{
				control: TextInput,
				label: 'Описание',
				name: 'description',
				initValue: accessPoint => accessPoint?.description,
			},
			{
				control: CheckboxInput,
				label: 'Оборудование АО «Ситроникс»',
				name: 'isSitronicsHardware',
				initValue: accessPoint => accessPoint?.isSitronicsHardware,
			},
		]
	},
	// Источники бесперебойного питания
	[ApiResource.UpsModel]: {
		title: 'Модели ИБП',
    	infoModalProps: commonImportExportInfoModal(),
		fieldList: namedRecordFieldList,
		formFieldList: namedRecordFormFieldList,
	},
	[ApiResource.UpsFirmware]: {
		title: 'Прошивки ИБП',
    	infoModalProps: commonImportExportInfoModal(),
		immutable: true,
		fieldList: firmwareFieldList,
		formFieldList: firmwareFormFieldList,
	},
	[ApiResource.UpsPreset]: {
		title: 'Пресеты ИБП',
		infoModalProps: commonImportExportInfoModal(),
		fileUploadRoute: apiRoutes.uploadUpsPresetFile,
		fieldList: [
			{ label: 'Имя', value: preset => preset.name },
			{
				label: 'Модель',
				value: preset => preset.model.name,
				path: preset => getApiResourceDetailsPath(ApiResource.UpsModel, preset.model.id)
			},
			{ label: 'Логин', value: preset => preset.login },
			{ label: 'Пароль', value: preset => preset.password },
		],
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: preset => preset?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.UpsModel,
				label: 'Модель',
				name: 'model',
				initValue: preset => preset?.model,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Логин',
				name: 'login',
				initValue: preset => preset?.login,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Пароль',
				name: 'password',
				initValue: preset => preset?.password,
			},
		],
	},
	[ApiResource.Ups]: {
		title: 'ИБП',
   	infoModalProps: commonImportExportInfoModal(ApiResource.Ups),
		getRecordTitle: ups => `${ups.code} [${ups.ipAddress}]`,
		fluid: true,
		fieldList: [
			{ label: 'ID', value: ups => ups.id },
			{ label: 'Имя', value: ups => ups.code },
			{ label: 'ID адреса ЕЦХД', value: ups => ups.address.storageId },
			{ label: 'Округ', value: ups => ups.address.district.area.name },
			{ label: 'Район', value: ups => ups.address.district.name },
			{ label: 'Адрес', value: ups => ups.address.name },
			{ label: 'Описание месторасположения', value: ups => ups.locationDescription },
			{ label: 'VRF', value: ups => ups.vrf?.name },
			{ label: 'IP-адрес', value: ups => ups.ipAddress },
			{ label: 'Подсеть', value: ups => ups.subnetwork },
			{
				label: 'IP-адрес коммутатора',
				value: ups => ups.networkSwitchPort?.networkSwitch.ipAddress,
				path: ups => ups.networkSwitchPort ? getApiResourceDetailsPath(ApiResource.NetworkSwitch, ups.networkSwitchPort.networkSwitch.id) : undefined
			},
			{ label: 'Порт ИБП на коммутаторе', value: ups => ups.networkSwitchPort?.portNumber },
			{ label: 'Статус', value: ups => ups.status.name },
			{
				label: 'Пресет',
				value: ups => ups.preset.name,
				path: ups => getApiResourceDetailsPath(ApiResource.UpsPreset, ups.preset.id)
			},
			{ label: 'Логин', value: ups => ups.preset.login },
			{ label: 'Пароль', value: ups => ups.preset.password },
			{
				label: 'Прошивка',
				value: ups =>
					<>
						{ups.firmware?.name}
						{ups.firmware?.isRecommended == null
							? ''
							: ' ' + (ups.firmware?.isRecommended ? '+' : '–')}
					</>
			},
			{
        label: 'Состояние',
        value: ups => ups.state.name,
        colorValue: ups => ups.state.code,
      },
			{ label: 'Серийный номер', value: ups => ups.serialNumber },
			{ label: 'Описание', value: ups => ups.description },
			{ label: 'Оборудование АО «Ситроникс»', value: ups => formatBoolean(ups.isSitronicsHardware)},
		],
		importApiUrl: apiRoutes.importUps,
		bindApiUrl: apiRoutes.importHardwareBind,
		exportApiUrl: apiRoutes.exportUps,
		formFieldList: [
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.HardwareStatus,
				label: 'Статус',
				name: 'status',
				initValue: ups => ups?.status,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Address,
				async: true,
        		recordToOption: addressToSelectOption,
				label: 'Адрес',
				name: 'address',
				initValue: ups => ups?.address,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Описание местоположения',
				name: 'locationDescription',
				initValue: ups => ups?.locationDescription,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Vrf,
				label: 'VRF',
				name: 'vrf',
				initValue: ups => ups?.vrf,
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'IP-адрес',
				name: 'ipAddress',
				initValue: ups => ups?.ipAddress,
				helpText: '255.255.255.255',
				required: form => !!form.isSitronicsHardware,
			},
			{
				control: TextInput,
				label: 'Подсеть',
				name: 'subnetwork',
				initValue: ups => ups?.subnetwork,
				helpText: '255.255.255.255/32',
			},
			{
				control: CheckboxInput,
				label: 'ИБП подключен через коммутатор',
				name: 'hasParentNetworkSwitch',
				initValue: ups => !!ups?.networkSwitchPort,
				helpText: 'Позволяет выбрать коммутатор',
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.NetworkSwitch,
				filter: form => ({ 'address.id': form.address, 'vrfs.id': form.vrf }),
				async: true,
				recordToOption: hardwareRecordToOption,
				label: 'Коммутатор',
				name: 'networkSwitch',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.networkSwitch,
				required: () => true,
				helpText: 'Коммутатор должен находиться на том же адресе и в том же VRF',
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: TextInput,
				label: 'Порт на коммутаторе',
				name: 'networkSwitchPortNumber',
				initValue: networkSwitch => networkSwitch?.networkSwitchPort?.portNumber,
				disabled: form => !form.hasParentNetworkSwitch
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.UpsPreset,
				label: 'Пресет',
				name: 'preset',
				initValue: ups => ups?.preset,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Серийный номер',
				name: 'serialNumber',
				initValue: ups => ups?.serialNumber,
			},
			{
				control: TextInput,
				label: 'Описание',
				name: 'description',
				initValue: ups => ups?.description,
			},
			{
				control: CheckboxInput,
				label: 'Оборудование АО «Ситроникс»',
				name: 'isSitronicsHardware',
				initValue: ups => ups?.isSitronicsHardware,
			},
		],
		cleanForm: form => {
			const cleanedForm: typeof form = {
				...form,
				networkSwitch: form.hasParentNetworkSwitch ? form.networkSwitch : null,
				networkSwitchPortNumber: form.hasParentNetworkSwitch ? form.networkSwitchPortNumber : null,
				hasParentNetworkSwitch: undefined,
			}
			return cleanedForm
		},
	},
	// ТВНы
	[ApiResource.CctvType]: {
		title: 'Типы ТВН',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: [
			{ label: 'Код', value: cctvType => cctvType.code },
			{ label: 'Имя', value: cctvType => cctvType.name },
			{ label: 'Основа кода для видеомаркера', value: cctvType => cctvType.videoMarkerBaseCode },
			{ label: 'Проект', value: cctvType => cctvType.project.name },
			{ label: 'Порт', value: cctvType => cctvType.storageIpCameraPort },
			{ label: 'Пропускная способность на одну камеру ТВН', value: cctvType => cctvType.capacityPerCctv },
		],
		exportApiUrl: apiRoutes.exportCctvTypes,
		formFieldList: [
			{
				control: TextInput,
				label: 'Код',
				name: 'code',
				initValue: cctvType => cctvType?.code,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: cctvType => cctvType?.name,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Основа кода для видеомаркера',
				name: 'videoMarkerBaseCode',
				initValue: cctvType => cctvType?.videoMarkerBaseCode,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Project,
				label: 'Проект',
				name: 'projectId',
				initValue: cctvType => cctvType?.project,
				required: () => true,
			},
			{
				control: NumberInput,
				label: 'Порт',
				name: 'storageIpCameraPort',
				initValue: cctvType => cctvType?.storageIpCameraPort,
			},
			{
				control: NumberInput,
				label: 'Пропускная способность на одну камеру ТВН',
				name: 'capacityPerCctv',
				initValue: cctvType => cctvType?.capacityPerCctv,
			},
		],
	},
	[ApiResource.CctvFileType]: {
		title: 'Типы файлов ТВН',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: namedRecordFieldList,
		exportApiUrl: apiRoutes.exportCctvFileTypes,
		formFieldList: namedRecordFormFieldList,
	},
	[ApiResource.PecFileType]: {
		title: 'Конструктор графических файлов',
		fieldList: [
			{ label: 'Имя', value: pecFileType => pecFileType.name },
      {
        label: 'Проекты',
        value: pecFileType => pecFileType.projects.length
          ? pecFileType.projects.map(project => <div key={project.id}>{project.name}</div>)
          : '-'
      },
      {
        label: 'Вид работ',
        value: pecFileType => pecFileType.pecStatusFields.length
          ? pecFileType.pecStatusFields.map(pecStatusField => <div key={pecStatusField.id}>{pecStatusField.name}</div>)
          : '-'
      },
		],
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: pecFileType => pecFileType?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Project,
				filter: () => ({ audit: 'true' }),
        isMulti: true,
				label: 'Проекты',
				name: 'projects',
				initValue: pecFileType => pecFileType?.projects,
        required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.PecStatusFields,
				filter: () => ({ audit: 'true' }),
        isMulti: true,
				label: 'Вид работ',
				name: 'pecStatusFields',
				initValue: pecFileType => pecFileType?.pecStatusFields,
        required: () => true,
			},
		],
	},
	[ApiResource.PecStatusFields]: {
		title: 'Конструктор видов работ',
		fieldList: [
			{ label: 'Имя', value: pecStatusField => pecStatusField.name },
      {
        label: 'Проекты',
        value: pecStatusField => pecStatusField.projects?.length
          ? pecStatusField.projects.map(project => <div key={project.id}>{project.name}</div>)
          : '-'
      },
		],
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: pecStatusField => pecStatusField?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Project,
				filter: () => ({ audit: 'true' }),
        isMulti: true,
				label: 'Проекты',
				name: 'projects',
				initValue: pecStatusField => pecStatusField?.projects,
        required: () => true,
			},
		],
	},
	[ApiResource.ChannelTechnology]: {
		title: 'Технологии каналов',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: namedRecordFieldList,
		exportApiUrl: apiRoutes.exportChannelTechnologies,
		formFieldList: namedRecordFormFieldList,
	},
	// Проекты
	[ApiResource.ProjectType]: {
		title: 'Типы проектов',
		fieldList: namedRecordFieldList,
		exportApiUrl: apiRoutes.exportProjectTypes,
		formFieldList: namedRecordFormFieldList,
    infoModalProps: commonImportExportInfoModal(),
  },
	[ApiResource.ProjectField]: {
		title: 'Поля проектов',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: [
			{ label: 'Имя', value: projectField => projectField.name },
		],
		exportApiUrl: apiRoutes.exportProjectFields,
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: projectField => projectField?.name,
				required: () => true,
			},
		],
		catalogListSort: { field: 'name', direction: 'asc' },
	},
	// Связь
	[ApiResource.Contract]: {
		title: 'ГК',
    infoModalProps: commonImportExportInfoModal(),
    fileUploadRoute: apiRoutes.uploadContractFile,
		fieldList: [
			{label: '№ ГК', value: contract => contract.number},
			{label: 'Название ГК', value: contract => contract.name},
			{label: 'Краткое название ГК', value: contract => contract.shortName},
			{label: 'Период', value: contract => contract.period},
			{label: 'Начало', value: contract => formatDate(contract.beginDate)},
			{label: 'Окончание', value: contract => formatDate(contract.endDate)},
			{label: 'Идентификатор', value: contract => contractIdentifierNames[contract.identifier]},
		],
		exportApiUrl: apiRoutes.exportContracts,
		formFieldList: [
			{
				control: TextInput,
				label: '№ ГК',
				name: 'number',
				initValue: contract => contract?.number,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Название ГК',
				name: 'name',
				initValue: contract => contract?.name,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Краткое название ГК',
				name: 'shortName',
				initValue: contract => contract?.shortName,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Период',
				name: 'period',
				initValue: contract => contract?.period,
				required: () => true,
			},
			{
				control: DateInput,
				label: 'Дата начала контракта',
				name: 'beginDate',
				initValue: contract => contract?.beginDate,
				required: () => true,
			},
			{
				control: DateInput,
				label: 'Дата окончания контракта',
				name: 'endDate',
				initValue: contract => contract?.endDate,
				required: () => true,
			},
			{
				control: ListSelect,
				label: 'Идентификатор',
				name: 'identifier',
				optionList: recordToOptions(contractIdentifierNames),
				initValue: contract => contract?.identifier,
				required: () => true,
			},
		]
	},
	[ApiResource.ServiceType]: {
		title: 'Типы услуг',
    infoModalProps: commonImportExportInfoModal(ApiResource.ServiceType),
		fieldList: [
			{ label: 'Имя', value: serviceType => serviceType.name },
			{ label: 'Вид типа услуги', value: serviceType => serviceType.typeKindName },
		],
		importApiUrl: apiRoutes.importServiceTypes,
		exportApiUrl: apiRoutes.exportServiceTypes,
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: serviceType => serviceType?.name,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Вид типа услуги',
				name: 'typeKindName',
				initValue: serviceType => serviceType?.typeKindName,
				required: () => true,
			},
		]
	},
	[ApiResource.ContractServiceTypePrice]: {
		title: 'Прайс-лист',
    infoModalProps: commonImportExportInfoModal(ApiResource.ContractServiceTypePrice),
		fieldList: [
			{label: '№', value: price => price.number},
			{label: '№ ГК', value: price => price.contract.number},
			{label: 'Тип услуги', value: price => price.serviceType.name},
			{label: 'Наименование', value: price => price.serviceName},
			{label: 'Пропускная способность, Мбит/с', value: price => price.channelCapacity},
			{label: 'Цена единицы услуги в месяц с учётом НДС, руб', value: price => price.price}
		],
		importApiUrl: apiRoutes.importContractServiceTypePrices,
		exportApiUrl: apiRoutes.exportContractServiceTypePrices,
		formFieldList: [
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Contract,
				recordToOption: recordToSelectOptionNumberLabel,
				label: '№ ГК',
				name: 'contract',
				initValue: price => price?.contract,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.ServiceType,
				label: 'Тип услуги',
				name: 'serviceType',
				initValue: price => price?.serviceType,
				required: () => true,
			},
			{
				control: TextInput,
				label: 'Наименование',
				name: 'serviceName',
				initValue: price => price?.serviceName,
				required: () => true,
			},
			{
				control: NumberInput,
				label: 'Пропускная способность, Мбит/с',
				name: 'channelCapacity',
				initValue: price => price?.channelCapacity,
				required: () => false,
			},
			{
				control: NumberInput,
				label: 'Цена единицы услуги в месяц с учётом НДС, руб',
				name: 'price',
				initValue: price => price?.price,
				required: () => true,
			}
		]
	},
	[ApiResource.Department]: {
		title: 'Департаменты потребителей',
    infoModalProps: commonImportExportInfoModal(ApiResource.Department),
		fieldList: [
			{ label: 'Имя', value: departament => departament.name },
			{ label: 'Нумерация', value: departament => departament.number },
		],
		importApiUrl: apiRoutes.importDepartments,
		exportApiUrl: apiRoutes.exportDepartments,
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: departament => departament?.name,
				required: () => true,
			},
			{
				control: NumberInput,
				label: 'Нумерация',
				name: 'number',
				initValue: departament => departament?.number,
				required: () => true,
			},
		],
	},
	[ApiResource.Consumer]: {
		title: 'Потребители',
    infoModalProps: commonImportExportInfoModal(ApiResource.Consumer),
		fieldList: [
			{ label: 'Имя', value: consumer => consumer.name },
			{
        label: 'Департамент',
        value: consumer => consumer.departments.length
          ? consumer.departments.map(department => <div key={department.id}>{department.name}</div>)
          : '-'
      },
		],
		importApiUrl: apiRoutes.importConsumers,
		exportApiUrl: apiRoutes.exportConsumers,
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: consumer => consumer?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				isMulti: true,
				apiResource: ApiResource.Department,
				label: 'Департамент',
				name: 'departments',
				initValue: consumer => consumer?.departments,
				required: () => true,
			},
		],
	},
	[ApiResource.ReportingPeriod]: {
		title: 'Отчётные периоды',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: [
      { label: '№ периода', value: reportPeriod => reportPeriod.number },
      { label: 'Наименование периода', value: reportPeriod => reportPeriod.name },
      {
        label: '№ ГК',
        value: reportPeriod => reportPeriod.contracts.length
          ? reportPeriod.contracts.map(contract => <div key={contract.id}>{contract.number}</div>)
          : '-'
      },
      { label: 'Начало', value: reportPeriod => formatDate(reportPeriod.beginDate) },
      { label: 'Окончание', value: reportPeriod => formatDate(reportPeriod.endDate) },
		],
		exportApiUrl: apiRoutes.exportReportingPeriods,
		formFieldList: [
			{
				control: TextInput,
				label: 'Наименование периода',
				name: 'name',
				initValue: reportPeriod => reportPeriod?.name,
				required: () => true,
			},
			{
				control: NumberInput,
				label: '№ периода',
				name: 'number',
				initValue: reportPeriod => reportPeriod?.number,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Contract,
				isMulti: true,
				recordToOption: recordToSelectOptionNumberLabel,
				label: '№ ГК',
				name: 'contracts',
				initValue: reportPeriod => reportPeriod?.contracts,
				required: () => true,
			},
			{
				control: DateInput,
				label: 'Дата начала',
				name: 'beginDate',
				initValue: reportPeriod => reportPeriod?.beginDate,
				required: () => true,
			},
      		{
				control: DateInput,
				label: 'Дата окончания',
				name: 'endDate',
				initValue: reportPeriod => reportPeriod?.endDate,
				required: () => true,
			},
    ],
		cleanForm: (form, setError) => {
			if (form.beginDate >= form.endDate) {
				setError?.('endDate', 'Дата окончания периода должна быть после даты начала')
				return
			}
			return form
		},
	},
	[ApiResource.CommunicationAddress]: {
		title: 'Адреса предоставления услуг по ГК',
		fieldList: [
			{ label: 'Имя', value: comAddress => comAddress.name },
			{ label: 'ЕЦХД ID', value: comAddress => comAddress.address.storageId },
			{ label: 'Округ', value: comAddress => comAddress.address.district.area.name },
			{ label: 'Район', value: comAddress => comAddress.address.district.name },
			{ label: 'Адрес', value: comAddress => comAddress.address.name },
		],
		importApiUrl: apiRoutes.importCommunicationAddresses,
		exportApiUrl: apiRoutes.exportCommunicationAddresses,
    infoModalProps: communicationAddressImportExportInfoModal(ApiResource.CommunicationAddress),
		formFieldList: [
			{
				control: TextInput,
				label: 'Имя',
				name: 'name',
				initValue: comAddress => comAddress?.name,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Address,
				async: true,
        recordToOption: addressToSelectOption,
				label: 'Адрес',
				name: 'address',
				initValue: comAddress => comAddress?.address,
				required: () => true,
			},
		],
		selectSort: { field: 'address.storageId', direction: 'asc' },
	},
	[ApiResource.Joint]: {
		title: 'Стыки',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: namedRecordFieldList,
		exportApiUrl: apiRoutes.exportJoints,
		formFieldList: namedRecordFormFieldList
	},
	[ApiResource.QualityClass]: {
		title: 'Классы качества',
    infoModalProps: commonImportExportInfoModal(),
		fieldList: namedRecordFieldList,
		exportApiUrl: apiRoutes.exportQualityClasses,
		formFieldList: namedRecordFormFieldList
  },
  [ApiResource.ConstructionObjectStatus]: {
    title: 'Статусы объектов строительства',
		fieldList: [
			{ label: 'Имя', value: status => status?.name },
      { label: 'Тип', value: status => constructionObjectStatusTypesNames[status?.type] },
    ],
    formFieldList: [
      {
        control: TextInput,
        label: 'Имя',
        name: 'name',
        initValue: status => status?.name,
        required: () => true,
      },
			{
				control: ListSelect,
				label: 'Тип',
				name: 'type',
				optionList: recordToOptions(constructionObjectStatusTypesNames),
        initValue: status => status?.type,
        required: () => true,
			},
    ]
  },
  [ApiResource.Agreement]: {
    title: 'Договоры',
		getRecordTitle: agreement => agreement.number,
    fileUploadRoute: apiRoutes.uploadAgreementFile,
		fieldList: [
			{ label: 'Номер', value: agreement => agreement?.number },
			{ label: 'Дата', value: agreement => formatDate(agreement?.date) },
      { label: 'Организация', value: agreement => agreement?.organization?.name },
    ],
    formFieldList: [
      {
        control: TextInput,
        label: 'Имя',
        name: 'number',
        initValue: agreement => agreement?.number,
        required: () => true,
      },
      {
        control: DateInput,
        label: 'Дата',
        name: 'date',
        initValue: agreement => agreement?.date,
        required: () => true,
      },
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Organization,
				label: 'Организация',
				name: 'organization',
				initValue: agreement => agreement?.organization,
				required: () => true,
			},
    ]
  },
	[ApiResource.OrderForm]: {
		title: 'Бланк-заказы',
		getRecordTitle: orderForm => orderForm.number,
		fileUploadRoute: apiRoutes.uploadOrderFormFile,
		fieldList: [
			{ label: 'Номер', value: orderForm => orderForm?.number },
			{ label: 'Организация', value: orderForm => orderForm?.organization.name },
			{
				label: 'Договоры',
				value: orderForm => orderForm?.agreements.map(agreement => <div key={agreement.id}>{agreement.number}</div>)
			},
			{
				label: 'Номер закупки из Jira',
        value: orderForm => orderForm?.purchaseNumberLinks?.map((link, i) => <div key={i}>{link}</div>,),
			},
			{ label: 'ИНН', value: orderForm => orderForm?.organization.inn },
			{ label: 'Дата подписания', value: orderForm => formatDate(orderForm?.signingDate) },
			{ label: 'Статус подписания', value: orderForm => orderForm?.signingStatus?.name },
		],
		formFieldList: [
			{
				control: TextInput,
				label: 'Номер',
				name: 'number',
				initValue: orderForm => orderForm?.number,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Organization,
				label: 'Организация',
				name: 'organization',
				initValue: agreement => agreement?.organization,
				required: () => true,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.Agreement,
				isMulti: true,
				recordToOption: recordToSelectOptionNumberLabel,
        filter: form => ({ 'organization.id': form.organization }),
				label: 'Договоры',
				name: 'agreements',
				initValue: agreement => agreement?.agreements,
				required: () => true,
			},
			{
				control: TextInput,
				isMulti: true,
				label: 'Номер закупки из Jira',
				name: 'purchaseNumberLinks',
				initValue: orderForm => orderForm?.purchaseNumberLinks,
			},
			{
				control: DateInput,
				label: 'Дата подписания',
				name: 'signingDate',
				initValue: orderForm => orderForm?.signingDate,
			},
			{
				control: ApiResourceSelect,
				apiResource: ApiResource.OrderFormSigningStatus,
				label: 'Статус подписания',
				name: 'signingStatus',
				initValue: orderForm => orderForm?.signingStatus,
			},
		]
	},
}
